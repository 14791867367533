<template>
  <TableWrap
    :formRef="formRef"
    :form="form"
    :search="search"
    :refresh="refresh"
    :pagination="pagination"
  >
    <template #queryform>
      <TableQueryForm ref="formRef" :model="form" :search="search">
        <a-form-item :wrapperCol="{ span: 20 }" label="消息查询" name="msgName">
          <a-input
            style="width: 200px"
            v-model:value="form.msgName"
            placeholder="请输入时间名称/Event事件"
          />
        </a-form-item>

        <a-form-item
          label="分类"
          :wrapperCol="{ span: 19, style: { width: '300px' } }"
          name="types"
        >
          <!-- <ProductSelect :multiple="true" /> -->
          <a-tree-select
            :disabled="false"
            v-model:value="form.types"
            style="width: 300px"
            :maxTagCount="3"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :replaceFields="{
              title: 'typeName',
              key: 'typeId',
              children: 'children',
              value: 'typeId',
            }"
            :tree-data="data"
            multiple
            placeholder="请选择分类"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item
          :wrapperCol="{ span: 19, style: { width: '200px' } }"
          label="适用产品"
          name="productId"
        >
          <a-tree-select
            :disabled="false"
            :value="form.productId"
            @change="
              (val) => {
                form.productId = val;
                form.productType = product.find(
                  (item) => (item.productId = val)
                ).productType;
              }
            "
            style="width: 200px"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :replaceFields="{
              title: 'productName',
              key: 'productId',
              children: 'subProducts',
              value: 'productId',
            }"
            :tree-data="product"
            placeholder="请选择适用产品"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item label="状态" name="ownStatus">
          <BaseSelect
            allowClear
            v-model:value="form.ownStatus"
            :optionList="status"
            valueKey="id"
            placeholder="请选择状态"
            labelKey="name"
          />
        </a-form-item>
      </TableQueryForm>
    </template>
    <template #toolbar>
      <a-button
        :loading="addLoding"
        type="primary"
        @click="
          () => {
            addRun();
          }
        "
      >
        获取全部消息权限
      </a-button>
    </template>
    <template #table>
      <a-table
        size="small"
        rowKey="servingId"
        bordered
        :columns="columns"
        v-bind="tableProps"
        :scroll="{ x: columnsWidth }"
        :pagination="false"
        :loading="tableProps.loading || delLoading"
      >
        <template #action="{ record }">
          <a-space :size="0" :key="record">
            <a-button
              v-if="record.docUrl"
              type="link"
              size="small"
              @click="
                () => {
                  window.open(record.docUrl);
                }
              "
            >
              查看文档
            </a-button>
            <a-button v-else type="link" disabled>暂无文档</a-button>
          </a-space>
        </template>
      </a-table>
    </template>
  </TableWrap>
  <DetailModal ref="detailModalRef" @reload="search.submit" />
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import DetailModal from "./components/DetailModal.vue";
import { useForm, useTable, useDel, useUpdate } from "./use";
import { status } from "../options";
import { getAllMessag, messageTypeList, productList } from "./services";
import { useRequest } from "xz-use";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    DetailModal,
    // ProductSelect,
  },

  setup() {
    const { form, formRef } = useForm() as any;
    const { data } = useRequest(() => messageTypeList(), {
      throwOnError: true,
      initialData: [],
    });
    const { data: product }: { data: any } = useRequest(() => productList(), {
      throwOnError: true,
      initialData: [],
    });

    const { run: addRun, loading: addLoding } = useRequest(
      () => {
        return getAllMessag();
      },
      {
        manual: true,
        onSuccess() {
          message.success("操作成功");
          search.submit();
        },
      }
    );

    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = useTable({
      form,
      formRef,
    });

    const detailModalRef = ref<any>(null);
    // 编辑
    const { handleGoEdit } = useUpdate({ detailModalRef });
    // 删除
    const { run: delRun, loading: delLoading } = useDel({ search });

    return {
      data,
      product,
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      pagination,
      detailModalRef,
      delRun,
      delLoading,
      handleGoEdit,
      status,
      window,
      addRun,
      addLoding,
    };
  },
});
</script>
<style lang="less" scoped>
.servingIcon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  border: 1px solid #e8e8e8;
}
</style>
