
import { ComputedRef, defineComponent, ref, provide, inject } from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import DetailModal from "./components/DetailModal.vue";
import { useForm, useTable, useDel, useUpdate } from "./use";
import { useSelectd } from "@/use/useSelectd";
import { PaginationConfig } from "ant-design-vue/lib/pagination";
import { XzcloudMessageStrategyPageListRes } from "./services";
import { useRoute, useRouter, Router } from "vue-router";

export default defineComponent({
  components: { DetailModal, PlusOutlined },
  setup() {
    const { form, formRef } = useForm();
    const activeKey = inject("activeKey");
    const router = useRouter();
    const { rowSelection } = useSelectd();
    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = useTable({
      form,
      formRef,
      router,
      activeKey,
    });

    const detailModalRef = ref<any>(null);
    // 编辑
    const { handleGoEdit } = useUpdate({ detailModalRef });
    // 删除
    const { run: delRun, loading: delLoading } = useDel({ search });
    provide("search", search as any);
    return {
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      pagination,
      detailModalRef,
      delRun,
      delLoading,
      handleGoEdit,
      rowSelection,
    };
  },
});
