// api接口
import { httpGet, httpPost } from "@/config/axios-config";
import { PageParams, PageRes, PaginatedParams } from "@/services/public";

// 新增
export const xzcloudApiInfoAdd = () => httpPost("/xzcloud/apiInfo/add");

// 列表查询（分页）
export interface XzcloudApiInfoPageListForm {
  /* API名称 */
  apiName: string;

  /* 分类ID集合 */
  types: number[];

  /* 适用产品ID */
  productId: number;

  /* 适用产品type */
  productType: number | null;

  /* 拥有状态 0-未拥有 1-已拥有 */
  ownStatus: number;
}
export type XzcloudApiInfoPageListParams = PageParams &
  XzcloudApiInfoPageListForm;
export interface XzcloudApiInfoPageListRes {
  /* Api ID */
  apiId: string;

  /* Api 名称 */
  apiName: string;

  /* Api 描述 */
  content: string;

  /* 文档地址 */
  docUrl: string;

  /* 接口限制信息 */
  limitInfo: string;

  /* 分类ID */
  typeId: string;

  /* 分类名称 */
  typeName: string;

  /* 拥有状态 0-未拥有 1-已拥有 */
  ownStatus: number;

  /* 适用系统集合 */
  applicableSysList: ApplicableSysList[];
}
interface ApplicableSysList {
  /* 系统ID */
  sysId: number;

  /* 系统名称 */
  sysName: string;

  /* 适用系统类型 1-主系统 2-子系统 */
  sysType: number;
}
export const xzcloudApiInfoPageList = (params: XzcloudApiInfoPageListParams) =>
  httpPost<PageRes<XzcloudApiInfoPageListRes>>(
    "/xzcloud/apiInfo/pageList",
    params
  );

export const xzcloudApiInfoPageListForTable = (
  pageParams: PaginatedParams[0],
  formData: XzcloudApiInfoPageListForm
) => {
  return xzcloudApiInfoPageList({ ...pageParams, ...formData }).then((res) => {
    return {
      total: res.total,
      list: res.records,
    };
  });
};

// 消息分类列表（不分页）
export interface XzcloudOperBackgroundTypeListRes {
  /* 分类ID */
  typeId: number;

  /* 分类父ID */
  parentId: number;

  /* 分类名称 */
  typeName: string;
  children: Child[];
}
interface Child {
  typeId: number;
  parentId: number;
  typeName: string;
  children: string[];
}
export const xzcloudOperBackgroundTypeList = () =>
  httpGet<XzcloudOperBackgroundTypeListRes[]>(
    "/xzcloud/operBackground/typeList"
  );

// 适用产品列表（不分页）
export interface XzcloudOperBackgroundProductListRes {
  /* 产品ID */
  productId: number;

  /* 父级ID */
  parentId: number;

  /* 产品名称 */
  productName: string;

  /* 产品类型 1-主产品 2-子产品 */
  productType: number;

  /* 子产品集合 */
  subProducts: SubProduct[];
}
interface SubProduct {
  productId: number;
  parentId: number;
  productName: string;
  productType: number;
  subProducts: string[];
}
export const xzcloudOperBackgroundProductList = () =>
  httpGet<XzcloudOperBackgroundProductListRes[]>(
    "/xzcloud/operBackground/productList"
  );

// 下拉
export const OwnStatusOptions = [
  { value: 0, label: "未获得" },
  { value: 1, label: "已获得" },
];
