import { httpGet, httpPost } from "@/config/axios-config";
import { PageParams, PageRes, PaginatedParams } from "@/services/public";

// 分页列表
export type OuterSysSelectListForm = {
  servingName?: string;
};
export type OuterSysSelectListParams = OuterSysSelectListForm & PageParams;
export interface OuterSysSelectListItem {
  applicationId: number | string | null;
  content: string;
  sysCode: string;
  sysName: string;
}
export const outerSysSelectList = (params: OuterSysSelectListParams) =>
  httpGet<PageRes<OuterSysSelectListItem>>(
    "/xzcloud/outerSys/selectList",
    params
  );
export const outerSysSelectListForTable = (
  pageParams: PaginatedParams[0],
  formData: OuterSysSelectListForm
) => {
  return outerSysSelectList({
    ...pageParams,
    ...formData,
  }).then((res) => {
    return {
      total: res.total,
      list: res.records,
    };
  });
};

// 不分页列表
export type GetStoreServingListRes = OuterSysSelectListItem;
export const getStoreServingList = () =>
  httpGet<GetStoreServingListRes[]>("/store/storeServing/list");

// 详情
export interface StoreServingDetailParams {}
export interface StoreServingDetailRes {
  createDate: string;
  desc: string;
  isDefault: boolean;
  servingIcon: string;
  servingId: string;
  servingName: string;
}
export const storeServingDetail = (params: StoreServingDetailParams) =>
  httpGet<StoreServingDetailRes>("/store/storeServing/detail", params);

// 删除
export interface outerSysDelParams {
  sysId: string;
}
export const outerSysDel = (params: outerSysDelParams) =>
  httpPost(`/xzcloud/outerSys/del?sysId=${params?.sysId}`, params);

export interface outerSysAddParams {
  content: string;
  sysCode: string;
  sysName: string;
  sysId?: string | number;
}
export const outerSysAdd = (params: outerSysAddParams) => {
  console.log(params, "params");
  return httpPost(
    !params.sysId ? "/xzcloud/outerSys/add" : "/xzcloud/outerSys/update",
    params
  );
};

export interface outerSysupdateParams {
  applicationId: number | string | null;
  content: string;
  sysCode: string;
  sysName: string;
}
export const outerSysupdate = (params: outerSysupdateParams) => {
  return httpPost("/xzcloud/outerSys/update", params);
};

export interface outerSysUpdateStatusParams {}
export const outerSysUpdateStatus = (params: outerSysUpdateStatusParams) => {
  return httpPost("/xzcloud/outerSys/updateEnable", params);
};
