import { httpGet, httpPost } from "@/config/axios-config";
import { PageParams, PageRes, PaginatedParams } from "@/services/public";

// 分页列表
export type MessagePageListForm = {
  servingName?: string;
};
export type MessagePageListParams = MessagePageListForm & PageParams;
export interface MessagePageListItem {}
export const MessagePageList = (params: MessagePageListParams) =>
  httpPost<PageRes<MessagePageListItem>>("/xzcloud/message/pageList", params);
export const MessagePageListForTable = (
  pageParams: PaginatedParams[0],
  formData: MessagePageListForm
) => {
  return MessagePageList({
    ...pageParams,
    ...formData,
  }).then((res) => {
    return {
      total: res.total,
      list: res.records,
    };
  });
};

// 消息分类列表
export type messageTypeListRes = MessagePageListItem;
export const messageTypeList = () =>
  httpGet<messageTypeListRes[]>("/xzcloud/operBackground/typeList");

//适用产品分类列表
export interface productListRes {}
export const productList = () =>
  httpGet<productListRes[]>("/xzcloud/operBackground/productList");

// 详情

export interface StoreServingDetailParams {
  servingId: string;
}
export interface StoreServingDetailRes {
  createDate: string;
  desc: string;
  isDefault: boolean;
  servingIcon: string;
  servingId: string;
  servingName: string;
}
export const storeServingDetail = (params: StoreServingDetailParams) =>
  httpGet<StoreServingDetailRes>("/store/storeServing/detail", params);

// 新增
export interface SaveOrUpdateStoreServingParams {
  servingId?: string;
  servingIcon: string;
  servingName: string;
  desc: string;
  isDefault: boolean;
}
export const saveOrUpdateStoreServing = (
  params: SaveOrUpdateStoreServingParams
) => httpPost("/store/storeServing/saveOrUpdate", params);

// 删除
export interface StoreServingParams {
  servingId: string;
}
export const delStoreServing = (params: StoreServingParams) =>
  httpPost("/store/storeServing/del", params);

export const getAllMessag = () => {
  return httpPost("/xzcloud/messageSub/getAllMessage");
};
