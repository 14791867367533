import { computed, reactive, ref } from "vue";
import { useAntdTable, useRequest } from "xz-use";
import { message } from "ant-design-vue";
import type { Search } from "@/services/public";
import {
  xzcloudApiInfoPageListForTable,
  OwnStatusOptions,
  XzcloudApiInfoPageListForm,
} from "@/services/apiList";

export const useForm = () => {
  const form = reactive({} as XzcloudApiInfoPageListForm);
  const formRef = ref<any>(null);
  return {
    form,
    formRef,
  };
};

export const useTable = ({ form, formRef }) => {
  const tableRes = useAntdTable(xzcloudApiInfoPageListForTable, {
    form,
    formRef,
    defaultParams: [{ pageSize: 20, current: 1 }],
  });

  const columns = [
    {
      title: "API名称",
      width: 120,
      dataIndex: "apiName",
      ellipsis: true,
    },
    { title: "分类", width: 90, dataIndex: "typeName", ellipsis: true },
    { title: "接口描述", width: 150, dataIndex: "content", ellipsis: true },
    {
      title: "接口限制",
      width: 80,
      dataIndex: "limitInfo",
      ellipsis: true,
    },
    {
      title: "适用产品",
      width: 80,
      dataIndex: "applicableSysList",
      ellipsis: true,
      customRender: ({ text }) => {
        return (text || []).map((it) => it.sysName).join("，");
      },
    },
    {
      title: "状态",
      width: 60,
      dataIndex: "ownStatus",
      customRender: ({ text }) => {
        return OwnStatusOptions.find((it) => it.value == text)?.label;
      },
    },
    {
      title: "操作",
      width: 60,
      fixed: "right",
      align: "center",
      slots: { customRender: "action" },
    },
  ];

  const columnsWidth = computed(() => {
    return columns.reduce((res, item) => {
      return res + item.width;
    }, 0);
  });

  return {
    tableRes,
    columns,
    columnsWidth,
  };
};
