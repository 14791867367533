
import { defineComponent, ref } from "vue";
import TypeSelect from "@/components/Select/TypeSelect.vue";
import ProductSelect from "@/components/Select/ProductSelect.vue";
import { useForm, useTable, statusList } from "./use";
import {
  updateEnableSubscribe,
  getAllExtPoint,
  subscribe,
  extPointDetail,
} from "@/services/extPointList";
import { useRequest } from "xz-use";
import { message } from "ant-design-vue/lib/components";
import PushUrlModal from "../components/PushUrlModal.vue";
import {
  xzcloudOperBackgroundTypeList,
  xzcloudOperBackgroundProductList,
} from "@/services/apiList";

export default defineComponent({
  components: {
    PushUrlModal,
  },
  setup() {
    const extStatus = ref<boolean>(false);
    const pushUrlModalRef = ref<any>(null);
    const { form, formRef } = useForm();
    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = useTable({
      form,
      formRef,
    });

    const { data: typeList } = useRequest(xzcloudOperBackgroundTypeList, {
      throwOnError: true,
      initialData: [],
    });

    const { data: productList } = useRequest(xzcloudOperBackgroundProductList, {
      throwOnError: true,
      initialData: [],
    });

    const { run: detailRun, data: detail } = useRequest(extPointDetail, {
      manual: true,
      onSuccess: (data) => {
        extStatus.value =
          Object.prototype.toString.call(data?.enableExtPoint) ===
          "[object Boolean]"
            ? data.enableExtPoint
            : false;
      },
    });
    detailRun();

    const { run: subscribeRun } = useRequest(subscribe, {
      manual: true,
      throwOnError: true,
      initialData: [],
      onSuccess: (data, params: any[]) => {
        search.submit();
        message.success("批量订阅成功");
      },
    });

    const { run: extRun } = useRequest(updateEnableSubscribe, {
      manual: true,
      throwOnError: true,
      initialData: [],
      onSuccess: (data, params) => {
        if (!params?.length) return;
        if (params[0]?.enableExtPoint === true) {
          message.success("订阅成功");
        } else {
          message.success("取消订阅成功");
        }
        extStatus.value = params[0]?.enableExtPoint;
      },
    });

    const { run: addRun, loading: addLoding } = useRequest(
      () => {
        return getAllExtPoint();
      },
      {
        manual: true,
        onSuccess() {
          message.success("操作成功");
          search.submit();
        },
      }
    );

    // 查看文档
    const handleShowDoc = (docUrl) => {
      if (!docUrl) return;
      window.open(docUrl, "_blank");
    };

    const selectedKeys = ref<any>([]);
    const onTableSelectChange = (keys, rows) => {
      selectedKeys.value = keys;
    };

    return {
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      pagination,
      handleShowDoc,
      addRun,
      addLoding,
      pushUrlModalRef,
      selectedKeys,
      onTableSelectChange,
      extStatus,
      extRun,
      subscribeRun,
      detail,
      statusList,
      detailRun,
      typeList,
      productList,
    };
  },
});
