
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import { useModalState, useRequest } from "xz-use";
import { randomStr } from "./use";
import { xzcloudApplicationSetEncodingKey } from "../services";

export default defineComponent({
  setup(props, { expose, emit }) {
    const form = ref({ encodingKey: "" });
    const formRef = ref<any>(null);
    const applicationId = ref();

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      afterOpen: ({ encodingAESKey, applicationId: id }) => {
        form.value.encodingKey = encodingAESKey;
        applicationId.value = id;
      },
      afterClose: () => {
        form.value = { encodingKey: "" };
        formRef.value.clearValidate();
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    const { run, loading } = useRequest(
      () =>
        xzcloudApplicationSetEncodingKey({
          applicationId: applicationId.value,
          ...form.value,
        }),
      {
        manual: true,
        onSuccess: () => {
          message.success("保存成功");
          emit("reload");
          handleClose();
        },
      }
    );

    const handleOk = async () => {
      try {
        await formRef.value?.validateFields();
        await run();
      } catch (e) {
        console.error(e);
      }
    };

    const handleRandom = () => {
      form.value.encodingKey = randomStr(43);
    };

    return {
      loading,
      form,
      formRef,
      visible,
      handleClose,
      handleOk,
      handleRandom,
    };
  },
});
