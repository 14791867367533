import { computed, reactive, ref, inject, provide } from "vue";
import { useAntdTable, useRequest } from "xz-use";
import {
  authorizeSelectPageListForTable,
  currentUserSysListForTable,
} from "../services";
import { sysType } from "../options";

// 新增
export const useAddOrUpdate = () => {
  return useRequest(
    (prams) => {
      return Promise.resolve();
    },
    {
      manual: true,
      throwOnError: true,
    }
  );
};

export const useDetail = ({ search }) => {
  search.submit();
};

//搜索用
export const useForm = () => {
  const form = reactive({});
  const formRef = ref<any>(null);
  provide("useForm", {
    form,
    formRef,
  });
  return {
    form,
    formRef,
  };
};

//查询表格
export const useTable = ({ form, formRef }) => {
  const tableRes = useAntdTable(currentUserSysListForTable as any, {
    form,
    formRef,
  });
  const columns = [
    {
      title: "系统类型",
      dataIndex: "sysType",
      width: 80,
      customRender: ({ text }) => {
        return sysType.find((item) => item.id == text)?.name;
      },
    },
    { title: "系统", dataIndex: "sysName", width: 80 },
    { title: "状态", dataIndex: "status", width: 80 }, //状态
  ];

  const columnsWidth = computed(() => {
    return columns.reduce((res, item) => {
      return res + item.width;
    }, 0);
  });
  provide("useTable", {
    tableRes,
    columns,
    columnsWidth,
  });
  return {
    tableRes,
    columns,
    columnsWidth,
  };
};
