<template>
  <TableWrap
    :formRef="formRef"
    :form="form"
    :search="search"
    :refresh="refresh"
    :pagination="pagination"
    :showPagination="false"
    :showToolbar="false"
    mode="modal"
  >
    <template #queryform>
      <TableQueryForm ref="formRef" :model="form" :search="search">
        <a-form-item label="授权系统" name="sysName">
          <a-input v-model:value="form.sysName" placeholder="请输入授权系统" />
        </a-form-item>
        <a-form-item label="类别" name="sysTypeList">
          <BaseSelect
            allowClear
            v-model:value="form.sysTypeList"
            :optionList="sysType"
            valueKey="id"
            placeholder="请选择类别"
            labelKey="name"
          />
        </a-form-item>
      </TableQueryForm>
    </template>
    <template #table>
      <a-table
        size="small"
        rowKey="sysId"
        :rowSelection="{ ...rowSelection, getCheckboxProps }"
        bordered
        :columns="columns"
        v-bind="tableProps"
        :scroll="{ x: columnsWidth }"
        :pagination="false"
        :loading="tableProps.loading"
      >
      </a-table>
    </template>
  </TableWrap>
</template>
<script lang="ts">
import { defineComponent, ref, inject, computed } from "vue";
import {} from "@ant-design/icons-vue";
import { useForm, useTable } from "../../use";
import { useSelectd, InjectSelected } from "@/use/useSelectd";
import { sysType } from "@/views/cloud/systemIntegration/authManage/list/options";
import { message } from "ant-design-vue";
import { AddApplicationAuthorizeSys } from "./service";
import { useRequest } from "xz-use";

export default defineComponent({
  components: {},
  setup() {
    const { form, formRef } = inject("useForm") as any;
    const { rowSelection } = InjectSelected();
    const getCheckboxProps = (record) => {
      return {
        disabled: !(record.status == "可授权"),
      };
    };

    const addRun = inject("addRun");
    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = inject("useTable") as any;

    return {
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      rowSelection,
      pagination,
      sysType,
      addRun,
      getCheckboxProps,
    };
  },
});
</script>
<style lang="less" scoped>
.servingIcon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  border: 1px solid #e8e8e8;
}
</style>
