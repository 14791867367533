import { computed, reactive, ref } from "vue";
import { useAntdTable, useRequest } from "xz-use";
import { message } from "ant-design-vue";
import type { Search } from "@/services/public";
import {
  delAuthorize,
  authorizeSelectPageListForTable,
  authorizeSelectPageListItem,
} from "./services";
import { authorizeStatus } from "./options";

export const useForm = () => {
  const form = reactive({
    authorizeStatusList: [],
    sysStatusList: [],
    sysTypeList: [],
    sysName: null,
  });

  const formRef = ref<any>(null);
  return {
    form,
    formRef,
  };
};

export const useTable = ({ form, formRef }) => {
  const tableRes = useAntdTable(authorizeSelectPageListForTable, {
    form,
    formRef,
  });

  const columns = [
    { title: "象征系统识别码", dataIndex: "sysCode", width: 80 },
    { title: "系统类型", dataIndex: "sysType", width: 80 },
    { title: "系统名称", dataIndex: "sysName", width: 80 },
    {
      title: "系统状态",
      dataIndex: "sysStatus",
      width: 80,
      // slots: {
      //   customRender: "sysStatus",
      // },
    },
    {
      title: "系统有效期",
      dataIndex: "",
      width: 80,
      slots: { customRender: "sysValidDate" },
    },
    {
      title: "授权状态",
      dataIndex: "authStatus",
      width: 80,
      customRender: ({ text }) => {
        return authorizeStatus.find((item) => item.id == text)?.name;
      },
    },
    {
      title: "授权有效期",
      dataIndex: "",
      width: 80,
      slots: { customRender: "authValidDate" },
    },
    {
      title: "操作",
      width: 120,
      fixed: "right",
      align: "center",
      slots: { customRender: "action" },
    },
  ];

  const columnsWidth = computed(() => {
    return columns.reduce((res, item) => {
      return res + item.width;
    }, 0);
  });
  return {
    tableRes,
    columns,
    columnsWidth,
  };
};

// 编辑打开弹窗
export const useUpdate = ({ detailModalRef }) => {
  const handleGoEdit = (record: authorizeSelectPageListItem) => {
    detailModalRef.value?.open(record);
  };
  return {
    handleGoEdit,
  };
};

// 删除
export const useDel = ({ search }: { search: Search }) => {
  return useRequest(delAuthorize, {
    manual: true,
    onSuccess: () => {
      message.success("删除成功");
      search.submit();
    },
  });
};
