
import { computed, defineComponent, ref, inject } from "vue";
import { useModalState, useRequest } from "xz-use";
import { message } from "ant-design-vue";
// import UploadImg from "@/components/UploadImg/index.vue";
import { messageStrategylist, MessageSubPageListItem } from "../services";
import { useSelectd, InjectSelected } from "@/use/useSelectd";
import { useForm } from "@/use/useForm";

const getDefaultForm = () => ({});

type FormValues = {
  [key: string]: any;
};

export default defineComponent({
  components: {
    // UploadImg
  },
  setup(props, { expose, emit }) {
    const { form, formRef, note, reset } = useForm(getDefaultForm()) as any;
    const { selected: outSelected } = InjectSelected();
    const { selected, rowSelection, clearSelectd, isSelectd } = useSelectd();
    const messageSubUpdateStrategiesRun: any = inject(
      "messageSubUpdateStrategiesRun"
    );
    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      beforeOpen: async (record: MessageSubPageListItem) => {
        if (record) {
          if (record.strategyId) {
            selected.selectedRowKeys = [record.strategyId as never];
          }
          note.value = record;
        }
        detailRun();
      },
      afterClose: () => {
        reset();
      },
    });

    const { run: detailRun, data } = useRequest(messageStrategylist, {
      manual: true,
      throwOnError: true,
      initialData: [],
      onSuccess: () => {
        // message.success("操作成功");
      },
    });

    const columns = [
      { title: "推送策略", dataIndex: "strategyName", width: 80 },
    ];

    expose({
      open: handleOpen,
      close: handleClose,
    });

    // 详情
    // 新增 || 修改
    const handleOk = async () => {
      try {
        // debugger;
        await formRef?.value?.validateFields?.();
        if (isSelectd()) {
          await messageSubUpdateStrategiesRun?.({
            strategyId: selected.selectedRowKeys[0],
            openMessageSubIds: !note.value.openMessageSubscribeId
              ? outSelected.selectedRowKeys
              : [note.value.openMessageSubscribeId],
          });
          emit("reload");
          handleClose();
        }
      } catch (e) {
        console.error(e);
      }
    };

    return {
      form,
      rowSelection,
      formRef,
      visible,
      handleClose,
      handleOk,
      columns,
      outSelected,
      data,
      formLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
      },
    };
  },
});
