import { computed, reactive, ref } from "vue";
import { useAntdTable } from "xz-use";
import { xzcloudApplicationSelectAuthToolForTable } from "./services";
import { authorizeStatus, appTypes } from "./options";

export const useForm = () => {
  const form = reactive({
    sysName: "",
    authStatus: null,
  });

  const formRef = ref<any>(null);
  return {
    form,
    formRef,
  };
};

export const useTable = ({ form, formRef }) => {
  const tableRes = useAntdTable(xzcloudApplicationSelectAuthToolForTable, {
    form,
    formRef,
  });

  const columns = [
    { title: "象征系统识别码", dataIndex: "sysCode", width: 80 },
    { title: "系统名称", dataIndex: "sysName", width: 80 },
    {
      title: "应用类型",
      dataIndex: "appTypes",
      width: 80,
      customRender: ({ text }) => {
        return appTypes.find((item) => item.id == text)?.name;
      },
    },
    { title: "三方系统ID", dataIndex: "thirdSysId", width: 80 },
    { title: "三方系统名称", dataIndex: "sysName", width: 80 },
    {
      title: "授权状态",
      dataIndex: "status",
      width: 80,
      customRender: ({ text }) => {
        return authorizeStatus.find((item) => item.id == text)?.name;
      },
    },
  ];

  const columnsWidth = computed(() => {
    return columns.reduce((res, item) => {
      return res + item.width;
    }, 0);
  });
  return {
    tableRes,
    columns,
    columnsWidth,
  };
};
