<template>
  <a-modal
    title="新增三方系统"
    placement="right"
    :width="600"
    :confirmLoading="detailLoading || addOrUpdateLoading"
    :visible="visible"
    :maskClosable="false"
    @close="handleClose"
    @cancel="handleClose"
    @ok="handleOk"
  >
    <a-form
      ref="formRef"
      :model="form"
      v-bind="{
        labelCol: { span: 6 },
        wrapperCol: { span: 15 },
      }"
    >
      <a-form-item
        label="三方系统识别码"
        name="sysCode"
        extra="三方系统识别码，是单个应用内，用于识别三方系统的唯一标识，支持6位数字+大写字母组合，
        创建后，请尽量不要进行修改，以免产生无法识别的风险"
        :rules="[
          { required: true, message: '三方系统识别码不能为空' },
          { min: 0, max: 8, message: '三方系统识别码不能超过8个字' },
        ]"
      >
        <a-space>
          <a-input :value="form.sysCode" placeholder="请生成三方系统识别码" />
          <a-button
            @click="
              () => {
                form.sysCode = String(
                  Math.random().toString(36).substring(2, 6) +
                    (Math.random() * 10000).toString().slice(0, 2)
                ).toUpperCase();
              }
            "
            >随机生成</a-button
          >
        </a-space>
      </a-form-item>
      <a-form-item
        label="三方系统名称"
        name="sysName"
        :rules="[
          { required: true, message: '三方系统名称不能为空' },
          { min: 0, max: 20, message: '三方系统名称不能超过20个字' },
        ]"
      >
        <a-input
          v-model:value="form.sysName"
          placeholder="请输入三方系统名称"
        />
      </a-form-item>
      <a-form-item
        label="备注"
        name="content"
        :rules="[{ min: 0, max: 100, message: '备注不能超过100个字' }]"
      >
        <a-textarea
          required
          placeholder="请输入备注信息"
          style="height: 150px; width: 330px"
          v-model:value="form.content"
        />
      </a-form-item>
    </a-form>

    <!-- <div class="footer">
      <a-button style="margin-right: 8px" @click="handleClose">取消</a-button>
      <a-button type="primary" @click="handleOk">确定</a-button>
    </div> -->
  </a-modal>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useModalState } from "xz-use";
import { message } from "ant-design-vue";
import UploadImg from "@/components/UploadImg/index.vue";
import { useDetail, useAddOrUpdate } from "./use";
import { outerSysAddParams, OuterSysSelectListItem } from "../services";
import round from "lodash/round";

const getDefaultForm = () => ({
  applicationId: null,
  content: "",
  sysCode: "",
  sysName: "",
});

// eslint-disable-next-line @typescript-eslint/ban-types
type FormValues = outerSysAddParams;

export default defineComponent({
  components: {
    // UploadImg
  },
  setup(props, { expose, emit }) {
    const form = ref<FormValues>(getDefaultForm());
    const formRef = ref<any>(null);

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      beforeOpen: async (record: OuterSysSelectListItem) => {
        if (record) {
          form.value = record;
        } else {
          form.value = getDefaultForm();
        }
      },
      afterClose: () => {
        formRef.value.clearValidate();
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    // 详情
    const { run: detailRun, loading: detailLoading } = useDetail();
    // 新增 || 修改
    const { run: addOrUpdateRun, loading: addOrUpdateLoading } =
      useAddOrUpdate();

    const handleOk = async () => {
      try {
        await formRef.value?.validateFields();
        await addOrUpdateRun({
          ...form.value,
        });
        message.success("保存成功");
        emit("reload");
        handleClose();
      } catch (e) {
        console.error(e);
      }
    };

    return {
      form,
      formRef,
      visible,
      handleClose,
      handleOk,
      detailRun,
      detailLoading,
      addOrUpdateRun,
      addOrUpdateLoading,
      Math,
      round,
    };
  },
});
</script>
<style lang="less" scoped>
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
</style>
