
import { defineComponent, ref } from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import DetailModal from "./components/DetailModal.vue";
import { useForm, useTable, useDel, useUpdate, useStatus } from "./use";

export default defineComponent({
  components: { DetailModal, PlusOutlined },
  setup() {
    const { form, formRef } = useForm();
    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = useTable({
      form,
      formRef,
    });

    const detailModalRef = ref<any>(null);
    // 编辑
    const { handleGoEdit } = useUpdate({ detailModalRef });
    // 删除
    const { run: delRun, loading: delLoading } = useDel({ search });
    const { updateStatusRun } = useStatus({ search });

    return {
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      pagination,
      detailModalRef,
      delRun,
      delLoading,
      handleGoEdit,
      updateStatusRun,
    };
  },
});
