
import { defineComponent, ref } from "vue";
import { useModalState } from "xz-use";

export default defineComponent({
  setup(props, { expose }) {
    const setModalRef = ref<any>(null);
    const whiteIpList = ref<string[]>([]);
    const applyDetialInfo = ref();

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      afterOpen: ({ setWhiteModalRef, ...info }) => {
        setModalRef.value = setWhiteModalRef;
        applyDetialInfo.value = info;
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    const handleOk = async () => {
      try {
        setModalRef.value.open(applyDetialInfo.value);
        handleClose();
      } catch (e) {
        console.error(e);
      }
    };

    return {
      applyDetialInfo,
      visible,
      whiteIpList,
      handleClose,
      handleOk,
    };
  },
});
