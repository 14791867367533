
import { defineComponent, ref } from "vue";
import { useModalState } from "xz-use";
import { message } from "ant-design-vue";
import UploadImg from "@/components/UploadImg/index.vue";
import { useDetail, useAddOrUpdate } from "./use";
import {
  SaveOrUpdateStoreServingParams,
  MessagePageListItem,
} from "../services";
import { key } from "@/store";

const getDefaultForm = () => ({});

type FormValues = {
  [key: string]: any;
};

export default defineComponent({
  components: { UploadImg },
  setup(props, { expose, emit }) {
    const form = ref<FormValues>(getDefaultForm());
    const formRef = ref<any>(null);

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      beforeOpen: async (record: MessagePageListItem) => {
        if (record) {
          const data = await detailRun({} as any);
          form.value = {};
        } else {
          form.value = getDefaultForm();
        }
      },
      afterClose: () => {
        formRef.value.clearValidate();
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    // 详情
    const { run: detailRun, loading: detailLoading } = useDetail();
    // 新增 || 修改
    const { run: addOrUpdateRun, loading: addOrUpdateLoading } =
      useAddOrUpdate();

    const handleOk = async () => {
      try {
        await formRef.value?.validateFields();
        await addOrUpdateRun({});
        message.success("保存成功");
        emit("reload");
        handleClose();
      } catch (e) {
        console.error(e);
      }
    };

    return {
      form,
      formRef,
      visible,
      handleClose,
      handleOk,
      detailRun,
      detailLoading,
      addOrUpdateRun,
      addOrUpdateLoading,
      formLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
      },
    };
  },
});
