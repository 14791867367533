<template>
  <TableWrap
    :formRef="formRef"
    :form="form"
    :search="search"
    :refresh="refresh"
    :pagination="pagination"
  >
    <template #queryform>
      <TableQueryForm ref="formRef" :model="form" :search="search">
        <a-form-item
          name="apiName"
          label="API名称"
          :labelCol="{ style: { width: '60px' } }"
        >
          <a-input
            v-model:value="form.apiName"
            placeholder="请输入API名称"
            allow-clear
          />
        </a-form-item>
        <a-form-item
          name="types"
          label="分类"
          :labelCol="{ style: { width: '40px' } }"
          :wrapperCol="{ style: { width: '300px' } }"
        >
          <a-tree-select
            v-model:value="form.types"
            style="width: 300px"
            :maxTagCount="3"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :replaceFields="{
              title: 'typeName',
              key: 'typeId',
              children: 'children',
              value: 'typeId',
            }"
            :tree-data="typeList"
            multiple
            allow-clear
            placeholder="请选择分类"
            tree-node-filter-prop="title"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item
          name="productId"
          label="适用产品"
          :labelCol="{ style: { width: '60px' } }"
        >
          <a-tree-select
            :value="form.productId"
            @change="
              (value, label, extra) => {
                form.productId = value;
                if (value == null) {
                  form.productType = null;
                } else {
                  form.productType = extra.triggerNode.dataRef.productType;
                }
              }
            "
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :replaceFields="{
              title: 'productName',
              key: 'productId',
              children: 'subProducts',
              value: 'productId',
            }"
            :tree-data="productList"
            placeholder="请选择适用产品"
            allow-clear
            show-search
            tree-node-filter-prop="title"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item
          name="ownStatus"
          label="状态"
          :labelCol="{ style: { width: '40px' } }"
        >
          <BaseSelect
            v-model:value="form.ownStatus"
            placeholder="全部"
            :optionList="OwnStatusOptions"
            allow-clear
          />
        </a-form-item>
      </TableQueryForm>
    </template>
    <template #toolbar>
      <a-button
        type="primary"
        :loading="addLoding"
        :onclick="
          () => {
            addRun();
          }
        "
        >获取全部api权限</a-button
      >
    </template>
    <template #table>
      <a-table
        size="small"
        rowKey="apiId"
        bordered
        :columns="columns"
        v-bind="tableProps"
        :scroll="{ x: columnsWidth }"
        :pagination="false"
      >
        <template #action="{ record }">
          <a-space :size="0">
            <a-button
              v-if="record.docUrl"
              type="link"
              size="small"
              @click="handleShowDoc(record.docUrl)"
              >查看文档</a-button
            >
            <a-button v-else type="link" disabled> 暂无文档 </a-button>
          </a-space>
        </template>
      </a-table>
    </template>
  </TableWrap>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import TypeSelect from "@/components/Select/TypeSelect.vue";
import ProductSelect from "@/components/Select/ProductSelect.vue";
import { useForm, useTable } from "./use";
import { OwnStatusOptions } from "@/services/apiList";
import { useRequest } from "xz-use";
import { message } from "ant-design-vue/lib/components";
import { getAllApi } from "../service";
import {
  xzcloudOperBackgroundTypeList,
  xzcloudOperBackgroundProductList,
} from "@/services/apiList";

export default defineComponent({
  components: {},
  setup() {
    const { form, formRef } = useForm();
    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = useTable({
      form,
      formRef,
    });

    const { data: typeList } = useRequest(xzcloudOperBackgroundTypeList, {
      throwOnError: true,
      initialData: [],
    });

    const { data: productList } = useRequest(xzcloudOperBackgroundProductList, {
      throwOnError: true,
      initialData: [],
    });

    const { run: addRun, loading: addLoding } = useRequest(
      () => {
        return getAllApi();
      },

      {
        manual: true,
        onSuccess() {
          message.success("操作成功");
          search.submit();
        },
      }
    );

    // 查看文档
    const handleShowDoc = (docUrl) => {
      window.open(docUrl, "_blank");
    };

    return {
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      pagination,
      handleShowDoc,
      OwnStatusOptions,
      addRun,
      addLoding,
      typeList,
      productList,
    };
  },
});
</script>
<style lang="less" scoped>
.servingIcon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  border: 1px solid #e8e8e8;
}
</style>
