import { httpGet, httpPost } from "@/config/axios-config";
import { PageParams, PageRes, PaginatedParams } from "@/services/public";
import { stringify } from "querystring";

// 分页列表
export type authorizeSelectPageListForm = {
  authorizeStatusList: [];
  sysTypeList: [];
  sysStatusList: [];
};
export type authorizeSelectPageListParams = authorizeSelectPageListForm &
  PageParams;
export interface authorizeSelectPageListItem {
  authValidEndDate: string;
  authValidStartDate: string;
  authorizeId: string;
  sysCode: string;
  sysName: string;
  sysStatus: string;
  authorizeStatus: string;
  sysType: string;
  sysValidEndDate: string;
}
export const authorizeSelectPageList = (
  params: authorizeSelectPageListParams
) => {
  return httpGet<PageRes<authorizeSelectPageListItem>>(
    "/xzcloud/application/authorizeSelectList",

    {
      ...params,
      authorizeStatusList: params?.authorizeStatusList?.join?.(","),
      sysTypeList: params?.sysTypeList?.join?.(","),
      sysStatusList: params?.sysStatusList?.join?.(","),
    }
  );
};
export const authorizeSelectPageListForTable = (
  pageParams: PaginatedParams[0],
  formData: authorizeSelectPageListForm
) => {
  return authorizeSelectPageList({
    ...pageParams,
    ...formData,
  }).then((res) => {
    return {
      total: res.total,
      list: res.records,
    };
  });
};

export type currentUserSysListForm = {
  [key: string]: any;
};
export type currentUserSysListParams = currentUserSysListForm & PageParams;
export interface currentUserSysListItem {}
export const currentUserSysList = (params: currentUserSysListParams) =>
  httpGet<PageRes<currentUserSysListItem>>(
    "/xzcloud/application/currentUserSysList",
    params
  );
export const currentUserSysListForTable = (
  pageParams: PaginatedParams[0],
  formData: currentUserSysListForm
) => {
  return currentUserSysList({
    ...pageParams,
    ...formData,
  }).then((res) => {
    // debugger;
    return {
      total: res.total || 1,
      list: res,
    };
  });
};

// 不分页列表
export type GetStoreServingListRes = authorizeSelectPageListItem;
export const getStoreServingList = () =>
  httpGet<GetStoreServingListRes[]>("/store/storeServing/list");

// 详情
export interface StoreServingDetailParams {
  authorizeId: string;
}
export interface StoreServingDetailRes {
  createDate: string;
  desc: string;
  isDefault: boolean;
  servingIcon: string;
  servingId: string;
  servingName: string;
}
export const storeServingDetail = (params: StoreServingDetailParams) =>
  httpGet<StoreServingDetailRes>("/store/storeServing/detail", params);

// 新增
export interface SaveOrUpdateStoreServingParams {
  servingId?: string;
  servingIcon: string;
  servingName: string;
  desc: string;
  isDefault: boolean;
}
export const saveOrUpdateStoreServing = (
  params: SaveOrUpdateStoreServingParams
) => httpPost("/store/storeServing/saveOrUpdate", params);

// 删除
export interface AuthorizeParams {
  authorizeId: string;
}
export const delAuthorize = (params: AuthorizeParams) =>
  httpGet("/xzcloud/application/delAuthorize", params);

export const updateAuthorizeStatus = (params) => {
  return httpPost("/xzcloud/application/updateAuthorizeStatus", params);
};
