<template>
  <a-modal
    title="设置回调URL"
    :width="600"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @cancel="handleClose"
    @ok="handleOk"
  >
    <a-form ref="formRef" :model="form">
      <a-form-item
        label="URL"
        name="authCallbackUrl"
        :rules="[
          { required: true, message: '请输入回调URL' },
          {
            pattern: /^(http(s?)|):\/\/(.+)$/,
            message: '请输入正确的url',
            trigger: 'blur',
          },
        ]"
      >
        <a-input v-model:value="form.authCallbackUrl" />
        <template #extra>
          必须以http://或https://开头，分别支持80端口和443端口。
          <br />
          地址需要公网可访问，象征云鉴权类事务，将通过此地址推送给对应的服务。
        </template>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script lang="ts">
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import { useModalState, useRequest } from "xz-use";
import { setAuthCallBackUrl } from "../services";

export default defineComponent({
  setup(props, { expose, emit }) {
    const form = ref({ authCallbackUrl: "" });
    const formRef = ref<any>(null);
    const applicationId = ref<any>();

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      afterOpen: ({ authCallbackUrl, applicationId: id }) => {
        form.value.authCallbackUrl = authCallbackUrl;
        applicationId.value = id;
      },
      afterClose: () => {
        form.value = { authCallbackUrl: "" };
        formRef.value.clearValidate();
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    const { run: setAuthCallBackUrlRun, loading } = useRequest(
      setAuthCallBackUrl,
      {
        manual: true,
        onSuccess: () => {
          message.success("保存成功");
          emit("reload");
          handleClose();
        },
      }
    );

    const handleOk = async () => {
      try {
        await formRef.value?.validateFields();
        await setAuthCallBackUrlRun({
          applicationId: applicationId.value,
          authCallbackUrl: form.value.authCallbackUrl,
        });
      } catch (e) {
        console.error(e);
      }
    };

    return {
      loading,
      form,
      formRef,
      visible,
      handleClose,
      handleOk,
    };
  },
});
</script>
