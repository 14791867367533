
import { defineComponent, provide, ref } from "vue";
import { useModalState, useRequest } from "xz-use";
import { message } from "ant-design-vue";
import {
  SaveOrUpdateStoreServingParams,
  authorizeSelectPageListItem,
} from "../../services";
import AuthorizeList from "./list/index.vue";
import { useAddOrUpdate, useForm, useTable } from "../use";
import { AddApplicationAuthorizeSys } from "./list/service";
import { useSelectd } from "@/use/useSelectd";
import { mapPick } from "@/utils/tools";

const getDefaultForm = () => ({
  servingId: undefined,
  servingName: "",
  servingIcon: [],
  desc: "",
  isDefault: true,
});

type FormValues = Omit<SaveOrUpdateStoreServingParams, "servingIcon"> & {
  servingIcon: string[];
};

export default defineComponent({
  components: {
    // UploadImg,
    AuthorizeList,
  },
  setup(props, { expose, emit }) {
    const form = ref<FormValues>(getDefaultForm());
    const { rowSelection, selected, isSelectd } = useSelectd();
    const formRef = ref<any>(null);
    const useFormRes = useForm();
    const useTableRes = useTable({
      form: useFormRes.form,
      formRef: useFormRes.formRef,
    });

    const { run: addRun } = useRequest(AddApplicationAuthorizeSys, {
      manual: true,
      throwOnError: true,
      onSuccess: () => {
        message.success("操作成功");
        useTableRes.tableRes.search.submit();
      },
    });

    provide("addRun", addRun);
    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      beforeOpen: async (record: authorizeSelectPageListItem) => {
        useTableRes.tableRes.search.submit();
      },
      afterClose: () => {},
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    // 新增 || 修改
    const { run: addOrUpdateRun, loading: addOrUpdateLoading } =
      useAddOrUpdate();

    const handleOk = async () => {
      if (isSelectd()) {
        // debugger;
        try {
          // console.log(selected.selectedRowKeys);
          await addRun({
            sysList: mapPick(selected.selectedRows, [
              "sysId",
              "sysType",
              "sysCode",
            ]),
          });
          message.success("保存成功");
          emit("reload");
          handleClose();
        } catch (e) {
          console.error(e);
        }
      }
    };

    return {
      form,
      formRef,
      visible,
      handleClose,
      handleOk,
      addOrUpdateRun,
      addOrUpdateLoading,
      selected,
    };
  },
});
