import { useRequest } from "xz-use";
import { storeServingDetail, saveOrUpdateStoreServing } from "../services";

// 详情
export const useDetail = () => {
  return useRequest(
    (params) => {
      return Promise.resolve(params);
    },
    {
      manual: true,
    }
  );
};

// 新增
export const useAddOrUpdate = () => {
  return useRequest(
    (params) => {
      return Promise.resolve();
    },
    {
      manual: true,
      throwOnError: true,
    }
  );
};
