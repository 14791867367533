import { useRequest } from "xz-use";
import { applicationCurrentInfo, XzcloudApplicationInfoRes } from "./services";

type XzcloudApplicationInfo = XzcloudApplicationInfoRes & {
  hideAppKey: string;
};

export const useDetail = () => {
  return useRequest(() => applicationCurrentInfo(), {
    formatResult: (data) => {
      const hideAppKey = data.appKey.replace(/./g, "*");
      let res: XzcloudApplicationInfo = {
        ...data,
        hideAppKey: hideAppKey,
      };
      return res;
    },
  });
};
