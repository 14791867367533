<template>
  <a-modal
    title="授权系统"
    placement="right"
    :width="1000"
    :confirmLoading="addOrUpdateLoading"
    :visible="visible"
    :maskClosable="false"
    @close="handleClose"
    @cancel="handleClose"
    @ok="handleOk"
  >
    <!-- {{ selected }} -->
    <AuthorizeList />
    <!-- <div class="footer">
      <a-button style="margin-right: 8px" @click="handleClose">取消</a-button>
      <a-button type="primary" @click="handleOk">确定</a-button>
    </div> -->
  </a-modal>
</template>
<script lang="ts">
import { defineComponent, provide, ref } from "vue";
import { useModalState, useRequest } from "xz-use";
import { message } from "ant-design-vue";
import {
  SaveOrUpdateStoreServingParams,
  authorizeSelectPageListItem,
} from "../../services";
import AuthorizeList from "./list/index.vue";
import { useAddOrUpdate, useForm, useTable } from "../use";
import { AddApplicationAuthorizeSys } from "./list/service";
import { useSelectd } from "@/use/useSelectd";
import { mapPick } from "@/utils/tools";

const getDefaultForm = () => ({
  servingId: undefined,
  servingName: "",
  servingIcon: [],
  desc: "",
  isDefault: true,
});

type FormValues = Omit<SaveOrUpdateStoreServingParams, "servingIcon"> & {
  servingIcon: string[];
};

export default defineComponent({
  components: {
    // UploadImg,
    AuthorizeList,
  },
  setup(props, { expose, emit }) {
    const form = ref<FormValues>(getDefaultForm());
    const { rowSelection, selected, isSelectd } = useSelectd();
    const formRef = ref<any>(null);
    const useFormRes = useForm();
    const useTableRes = useTable({
      form: useFormRes.form,
      formRef: useFormRes.formRef,
    });

    const { run: addRun } = useRequest(AddApplicationAuthorizeSys, {
      manual: true,
      throwOnError: true,
      onSuccess: () => {
        message.success("操作成功");
        useTableRes.tableRes.search.submit();
      },
    });

    provide("addRun", addRun);
    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      beforeOpen: async (record: authorizeSelectPageListItem) => {
        useTableRes.tableRes.search.submit();
      },
      afterClose: () => {},
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    // 新增 || 修改
    const { run: addOrUpdateRun, loading: addOrUpdateLoading } =
      useAddOrUpdate();

    const handleOk = async () => {
      if (isSelectd()) {
        // debugger;
        try {
          // console.log(selected.selectedRowKeys);
          await addRun({
            sysList: mapPick(selected.selectedRows, [
              "sysId",
              "sysType",
              "sysCode",
            ]),
          });
          message.success("保存成功");
          emit("reload");
          handleClose();
        } catch (e) {
          console.error(e);
        }
      }
    };

    return {
      form,
      formRef,
      visible,
      handleClose,
      handleOk,
      addOrUpdateRun,
      addOrUpdateLoading,
      selected,
    };
  },
});
</script>
<style lang="less" scoped>
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
</style>
