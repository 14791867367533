import { computed, reactive, Ref, ref } from "vue";
import { useAntdTable, useRequest } from "xz-use";
import { message } from "ant-design-vue";
import type { Search } from "@/services/public";
import {
  xzcloudMessageStrategyDel,
  xzcloudMessageStrategyPageListForTable,
} from "./services";
import { format } from "@/utils/date";
import { useRoute, useRouter, Router } from "vue-router";

export const useForm = () => {
  const form = reactive({ strategyName: null });
  const formRef = ref<any>(null);
  return {
    form,
    formRef,
  };
};

export const useTable = ({
  form,
  formRef,
  router,
  activeKey,
}: {
  form: any;
  formRef: any;
  router: Router;
  activeKey: any;
}) => {
  const tableRes = useAntdTable(xzcloudMessageStrategyPageListForTable, {
    form,
    formRef,
  });
  const columns = [
    {
      title: "序号",
      dataIndex: "",
      width: 80,
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    { title: "策略名称", dataIndex: "strategyName", width: 80 },
    { title: "备注", dataIndex: "content", width: 80 },
    {
      title: "消息数",
      dataIndex: "msgCount",
      width: 80,
      customRender: ({ text, record }) => {
        return (
          <a-button
            type="link"
            onClick={() => {
              // activeKey.value = 1;
              window.open(
                `/cloud/messagePush/messageSubscript/list?strategyId=${record.strategyId}`
              );
            }}
          >
            {text}
          </a-button>
        );
      },
    },
    { title: "创建人", dataIndex: "userName", width: 80 },
    {
      title: "创建时间",
      dataIndex: "createDate",
      width: 80,
      customRender: ({ text }) => {
        return format(text);
      },
    },
    {
      title: "操作",
      width: 120,
      fixed: "right",
      align: "center",
      slots: { customRender: "action" },
    },
  ];

  const columnsWidth = computed(() => {
    return columns.reduce((res, item) => {
      return res + item.width;
    }, 0);
  });
  return {
    tableRes,
    columns,
    columnsWidth,
  };
};

// 编辑
export const useUpdate = ({ detailModalRef }) => {
  const handleGoEdit = (record: any) => {
    detailModalRef.value?.open(record);
  };
  return {
    handleGoEdit,
  };
};

// 删除
export const useDel = ({ search }: { search: Search }) => {
  return useRequest(xzcloudMessageStrategyDel, {
    manual: true,
    onSuccess: () => {
      message.success("删除成功");
      search.submit();
    },
  });
};
