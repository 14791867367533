<template>
  <a-space direction="vertical" :size="16" style="width: 100%">
    <a-card bodyStyle="padding: 12px">
      <a-space direction="vertical">
        <a-space
          ><span style="font-size: 16px; font-weight: 500">扩展点订阅</span
          ><a-switch
            :checked="extStatus"
            @change="
              (val) => {
                extRun({ enableExtPoint: val });
              }
            "
          ></a-switch
        ></a-space>
        <div>
          扩展点订阅能力开启后，象征云会通过本应用，将已订阅的扩展点请求实时推送给回调URL对应的服务。
        </div>
        <div>推送方式：支持http/https调用，建议使用https</div>
        <a-space
          >推送地址：{{ detail?.extPointUrl || "未设置"
          }}<a
            href="javascript:;"
            @click="pushUrlModalRef?.open(detail?.extPointUrl)"
            >编辑</a
          ></a-space
        >
      </a-space>
    </a-card>
    <TableWrap
      :formRef="formRef"
      :form="form"
      :search="search"
      :refresh="refresh"
      :pagination="pagination"
    >
      <template #queryform>
        <TableQueryForm ref="formRef" :model="form" :search="search">
          <a-form-item
            name="extPointName"
            label="扩展点查询"
            :labelCol="{ style: { width: '70px' } }"
          >
            <a-input
              v-model:value="form.extPointName"
              placeholder="请输入扩展点名称"
              allow-clear
            />
          </a-form-item>
          <a-form-item
            name="apiType"
            label="分类"
            :labelCol="{ style: { width: '40px' } }"
            :wrapperCol="{ style: { width: '300px' } }"
          >
            <a-tree-select
              v-model:value="form.apiType"
              style="width: 300px"
              :maxTagCount="3"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :replaceFields="{
                title: 'typeName',
                key: 'typeId',
                children: 'children',
                value: 'typeId',
              }"
              :tree-data="typeList"
              multiple
              allow-clear
              placeholder="请选择分类"
              tree-node-filter-prop="title"
            >
            </a-tree-select>
          </a-form-item>
          <a-form-item
            name="productAppId"
            label="适用产品"
            :labelCol="{ style: { width: '60px' } }"
          >
            <a-tree-select
              v-model:value="form.productAppId"
              @change="
                (value, label, extra) => {
                  if (value == null) {
                    form.productAppType = null;
                  } else {
                    form.productAppType = extra.triggerNode.dataRef.productType;
                  }
                }
              "
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :replaceFields="{
                title: 'productName',
                key: 'productId',
                children: 'subProducts',
                value: 'productId',
              }"
              :tree-data="productList"
              placeholder="请选择适用产品"
              allow-clear
              show-search
              tree-node-filter-prop="title"
            >
            </a-tree-select>
          </a-form-item>
          <a-form-item
            name="status"
            label="状态"
            :labelCol="{ style: { width: '40px' } }"
          >
            <BaseSelect
              v-model:value="form.status"
              placeholder="全部"
              :optionList="statusList"
              allow-clear
            />
          </a-form-item>
        </TableQueryForm>
      </template>
      <template #toolbar>
        <a-space>
          <a-button
            type="primary"
            :loading="addLoding"
            :onclick="
              () => {
                addRun();
              }
            "
            >获取全部扩展点权限</a-button
          >
          <a-button
            :disabled="!selectedKeys.length"
            @click="
              () => {
                subscribeRun({ status: 2, ids: selectedKeys });
              }
            "
            >批量订阅</a-button
          >
        </a-space>
      </template>
      <template #table>
        <a-table
          size="small"
          rowKey="openExtPointId"
          bordered
          :columns="columns"
          v-bind="tableProps"
          :scroll="{ x: columnsWidth }"
          :row-selection="{
            selectedRowKeys: selectedKeys,
            onChange: onTableSelectChange,
            getCheckboxProps: (record) => {
              return { disabled: !record.status };
            },
          }"
          :pagination="false"
        >
          <template #action="{ record }">
            <a-space>
              <a-button
                v-if="record.status === 1"
                type="link"
                size="small"
                @click="
                  () => {
                    subscribeRun({ status: 2, ids: [record.openExtPointId] });
                  }
                "
                >订阅</a-button
              >
              <a-popconfirm
                v-if="record.status === 2"
                title="你确定要取消订阅吗？"
                @confirm="
                  () => {
                    subscribeRun({ status: 1, ids: [record.openExtPointId] });
                  }
                "
                ><a-button type="link" size="small"
                  >取消订阅</a-button
                ></a-popconfirm
              >
              <a-button
                v-if="record.docUrl"
                type="link"
                size="small"
                @click="handleShowDoc(record.docUrl)"
                >查看文档</a-button
              >
              <a-button v-else type="link" disabled> 暂无文档 </a-button>
            </a-space>
          </template>
        </a-table>
      </template>
    </TableWrap>
  </a-space>
  <PushUrlModal ref="pushUrlModalRef" :load="detailRun" />
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import TypeSelect from "@/components/Select/TypeSelect.vue";
import ProductSelect from "@/components/Select/ProductSelect.vue";
import { useForm, useTable, statusList } from "./use";
import {
  updateEnableSubscribe,
  getAllExtPoint,
  subscribe,
  extPointDetail,
} from "@/services/extPointList";
import { useRequest } from "xz-use";
import { message } from "ant-design-vue/lib/components";
import PushUrlModal from "../components/PushUrlModal.vue";
import {
  xzcloudOperBackgroundTypeList,
  xzcloudOperBackgroundProductList,
} from "@/services/apiList";

export default defineComponent({
  components: {
    PushUrlModal,
  },
  setup() {
    const extStatus = ref<boolean>(false);
    const pushUrlModalRef = ref<any>(null);
    const { form, formRef } = useForm();
    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = useTable({
      form,
      formRef,
    });

    const { data: typeList } = useRequest(xzcloudOperBackgroundTypeList, {
      throwOnError: true,
      initialData: [],
    });

    const { data: productList } = useRequest(xzcloudOperBackgroundProductList, {
      throwOnError: true,
      initialData: [],
    });

    const { run: detailRun, data: detail } = useRequest(extPointDetail, {
      manual: true,
      onSuccess: (data) => {
        extStatus.value =
          Object.prototype.toString.call(data?.enableExtPoint) ===
          "[object Boolean]"
            ? data.enableExtPoint
            : false;
      },
    });
    detailRun();

    const { run: subscribeRun } = useRequest(subscribe, {
      manual: true,
      throwOnError: true,
      initialData: [],
      onSuccess: (data, params: any[]) => {
        search.submit();
        message.success("批量订阅成功");
      },
    });

    const { run: extRun } = useRequest(updateEnableSubscribe, {
      manual: true,
      throwOnError: true,
      initialData: [],
      onSuccess: (data, params) => {
        if (!params?.length) return;
        if (params[0]?.enableExtPoint === true) {
          message.success("订阅成功");
        } else {
          message.success("取消订阅成功");
        }
        extStatus.value = params[0]?.enableExtPoint;
      },
    });

    const { run: addRun, loading: addLoding } = useRequest(
      () => {
        return getAllExtPoint();
      },
      {
        manual: true,
        onSuccess() {
          message.success("操作成功");
          search.submit();
        },
      }
    );

    // 查看文档
    const handleShowDoc = (docUrl) => {
      if (!docUrl) return;
      window.open(docUrl, "_blank");
    };

    const selectedKeys = ref<any>([]);
    const onTableSelectChange = (keys, rows) => {
      selectedKeys.value = keys;
    };

    return {
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      pagination,
      handleShowDoc,
      addRun,
      addLoding,
      pushUrlModalRef,
      selectedKeys,
      onTableSelectChange,
      extStatus,
      extRun,
      subscribeRun,
      detail,
      statusList,
      detailRun,
      typeList,
      productList,
    };
  },
});
</script>
<style lang="less" scoped>
.servingIcon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  border: 1px solid #e8e8e8;
}
</style>
