<template>
  <a-card size="small" :bordered="false" class="cardWrapper">
    <!-- <div class="spinWrapper" v-show="loading">
      <a-spin size="large" />
    </div> -->
    <a-descriptions title="基本信息" :column="1" v-bind="{ ...style }">
      <a-descriptions-item>
        <div class="apply-info">
          <span class="title">{{ applyDetialInfo?.applicationName }}</span>
          <a-tag color="#108ee9"
            >{{
              applyDetialInfo?.applicationType == 1 ? "自用" : "工具"
            }}型-无容器</a-tag
          >
        </div>
      </a-descriptions-item>
      <a-descriptions-item label="应用简介">
        {{ applyDetialInfo?.applicationContent }}
      </a-descriptions-item>
      <a-descriptions-item label="创建时间">
        {{ applyDetialInfo?.createDate }}
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions title="开发信息" :column="1" v-bind="{ ...style }">
      <a-descriptions-item label="appKey">
        <div>
          <span class="appkey" @click="handleChangeHide">
            <span :class="isHide ? 'hide' : ''">
              {{ applyDetialInfo?.appKey }}
            </span>
            <span class="hide-appkey" v-if="isHide">
              {{ applyDetialInfo?.hideAppKey }}
            </span>
            <EyeOutlined class="eye-icon" v-if="!isHide" />
            <EyeInvisibleOutlined class="eye-icon" v-if="isHide" />
          </span>
          <a-button type="link" @click="handleCopy">复制</a-button>
        </div>
      </a-descriptions-item>
      <a-descriptions-item label="AppSecret">
        <a-button
          type="link"
          @click="handleConfirmAppSecret"
          style="padding-left: 0"
        >
          {{ applyDetialInfo?.appSecret ? "重置" : "获取" }}
        </a-button>
      </a-descriptions-item>
      <a-descriptions-item label="EncodingAESKey">
        <a-button
          type="link"
          @click="encodingAESKeyModalRef?.open(applyDetialInfo)"
          style="padding-left: 0"
        >
          查看
        </a-button>
      </a-descriptions-item>
      <a-descriptions-item label="回调URL">
        {{ applyDetialInfo?.authCallbackUrl }}
        <a-button
          type="link"
          @click="setUrlModelRef?.open(applyDetialInfo)"
          :style="{
            paddingLeft: applyDetialInfo?.authCallbackUrl ? '20px' : 0,
          }"
          >查看</a-button
        >
      </a-descriptions-item>
      <a-descriptions-item label="IP白名单">
        <a-button
          type="link"
          @click="
            whiteModalRef?.open({
              setWhiteModalRef: setWhiteModalRef,
              ...applyDetialInfo,
            })
          "
          style="padding-left: 0"
        >
          查看
        </a-button>
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions title="授权信息" :column="1" v-bind="{ ...style }">
      <a-descriptions-item label="已授权系统数">
        {{ applyDetialInfo?.authorizeSysNum }}
      </a-descriptions-item>
      <a-descriptions-item>
        <router-link to="/cloud/systemIntegration/authManage/list">
          <a-button type="link" style="padding-left: 0">
            {{
              applyDetialInfo?.authorizeSysNum ? "查看系统授权" : "新增系统授权"
            }}
          </a-button>
        </router-link>
      </a-descriptions-item>
    </a-descriptions>
  </a-card>
  <AppSecretModal ref="appSecretModalRef" @reload="detailRun" />
  <EncodingAESKeyModal ref="encodingAESKeyModalRef" @reload="detailRun" />
  <SetUrlModel ref="setUrlModelRef" @reload="detailRun" />
  <WhiteModal ref="whiteModalRef" />
  <SetWhiteModal ref="setWhiteModalRef" @reload="detailRun" />
</template>

<script lang="ts">
import { defineComponent, ref, createVNode } from "vue";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import useClipboard from "vue-clipboard3";
import { message, Modal } from "ant-design-vue";
import useUser from "@/use/useUser";
import AppSecretModal from "./components/AppSecretModal.vue";
import EncodingAESKeyModal from "./components/EncodingAESKeyModal.vue";
import SetUrlModel from "./components/SetUrlModel.vue";
import WhiteModal from "./components/WhiteModal.vue";
import SetWhiteModal from "./components/SetWhiteModal.vue";
import { useDetail } from "./use";

export default defineComponent({
  components: {
    EyeOutlined,
    EyeInvisibleOutlined,
    AppSecretModal,
    EncodingAESKeyModal,
    SetUrlModel,
    WhiteModal,
    SetWhiteModal,
  },
  setup() {
    const { applyInfo } = useUser();
    const labelStyle = {
      width: "120px",
      paddingLeft: "20px",
      lineHeight: "26px",
    };
    const contentStyle = { paddingLeft: "20px", lineHeight: "26px" };
    const isHide = ref<boolean>(true);
    const appSecretModalRef = ref<any>();
    const encodingAESKeyModalRef = ref<any>();
    const setUrlModelRef = ref<any>();
    const whiteModalRef = ref<any>();
    const setWhiteModalRef = ref<any>();
    const { toClipboard } = useClipboard();

    // 应用详情
    const { run: detailRun, data: applyDetialInfo } = useDetail();

    const handleChangeHide = () => {
      isHide.value = !isHide.value;
    };

    // 复制
    const handleCopy = async () => {
      await toClipboard(applyDetialInfo?.value?.appKey);
      message.success("复制成功");
    };

    // 获取重置AppSecret
    const handleConfirmAppSecret = () => {
      // 有appSecret
      const hasAppSecret = applyDetialInfo?.value?.appSecret ? true : false;
      const text = hasAppSecret ? "重置" : "获取";
      Modal.confirm({
        title: () => `你确定要${text}开发者密码(AppSecret)吗？`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          "请注意：重置AppSecret立即生效，所有使用旧AppSecret的接口将立即失效。为确保公众号的正常使用，请尽快更新AppSecret信息。",
        okText: `确定${text}`,
        onOk() {
          appSecretModalRef.value.open({
            applicationId: applyInfo.value?.applicationId,
            hasAppSecret: hasAppSecret,
          });
        },
      });
    };

    return {
      style: { labelStyle, contentStyle },
      isHide,
      applyDetialInfo,
      appSecretModalRef,
      encodingAESKeyModalRef,
      setUrlModelRef,
      whiteModalRef,
      setWhiteModalRef,
      handleCopy,
      handleChangeHide,
      handleConfirmAppSecret,
      detailRun,
    };
  },
});
</script>

<style lang="less" scoped>
.cardWrapper {
  position: relative;
  .apply-info {
    .title {
      // margin-right: 60px;
      margin-right: 30px;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .appkey {
    // letter-spacing: 1px;
    position: relative;
    .hide {
      visibility: hidden;
    }

    .hide-appkey {
      position: absolute;
      left: 0;
      top: 0px;
    }
    .eye-icon {
      margin-left: 35px;
      margin-right: 20px;
    }
  }

  .des {
    color: #999;
  }
}
</style>
