// 授权管理(工具型),列表查询
import { httpGet, httpPost } from "@/config/axios-config";
import { PageParams, PageRes, PaginatedParams } from "@/services/public";

// 授权管理(工具型),列表查询
export interface XzcloudApplicationSelectAuthToolForm {
  /* 应用id */
  applicationId: number;

  /* 授权状态 */
  authStatus: number;

  /* 系统名称 */
  sysName: string;
}
export type XzcloudApplicationSelectAuthToolParams = PageParams &
  XzcloudApplicationSelectAuthToolForm;
export interface XzcloudApplicationSelectAuthToolRes {}
export const xzcloudApplicationSelectAuthTool = (
  params: XzcloudApplicationSelectAuthToolParams
) =>
  httpPost<PageRes<XzcloudApplicationSelectAuthToolRes>>(
    "/xzcloud/application/selectAuthTool",
    params
  );

export const xzcloudApplicationSelectAuthToolForTable = (
  pageParams: PaginatedParams[0],
  formData: XzcloudApplicationSelectAuthToolForm
) => {
  return xzcloudApplicationSelectAuthTool({ ...pageParams, ...formData }).then(
    (res) => {
      return {
        total: res.total,
        list: res.records,
      };
    }
  );
};
