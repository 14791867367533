
import { defineComponent, ref } from "vue";
import { useModalState, useRequest } from "xz-use";
import useClipboard from "vue-clipboard3";
import { CheckOutlined } from "@ant-design/icons-vue";
import { xzcloudApplicationRemakeAndGetAppSecret } from "../services";

export default defineComponent({
  components: { CheckOutlined },
  setup(props, { expose, emit }) {
    const appSecret = ref<string>("");
    const hasAppSecret = ref<boolean>();
    const { toClipboard } = useClipboard();
    const { run: getAppSecret } = useRequest(
      xzcloudApplicationRemakeAndGetAppSecret,
      {
        manual: true,
        onSuccess: (data) => {
          appSecret.value = data as string;
        },
      }
    );
    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      beforeOpen: async ({ applicationId, hasAppSecret: has }) => {
        // 重置或获取appSecret
        hasAppSecret.value = has;
        await getAppSecret({ applicationId });
        await handleCopy(appSecret.value);
      },
      beforeClose: () => {
        emit("reload");
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    // 复制
    const handleCopy = async (text) => {
      await toClipboard(text);
    };

    const handleOk = async () => {};

    return {
      appSecret,
      visible,
      hasAppSecret,
      handleClose,
      handleOk,
    };
  },
});
