import moment from "moment";
import range from "lodash/range";

//禁止过去的时间
export const disabledPastDate = (current) => {
  return current.valueOf() < moment().startOf("day").valueOf();
};

//禁止未来的时间
export const disabledFutureDate = (current) => {
  return current.valueOf() > moment().startOf("day").valueOf();
};

export const month = range(1, 13).map((item) => ({ id: item, name: item }));

export const day = (month) => {
  if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
    //大月
    return range(1, 32).map((item) => ({ id: item, name: item }));
  } else if ([4, 6, 9, 1].includes(month)) {
    //小月
    return range(1, 31).map((item) => ({ id: item, name: item }));
  } else {
    //2月
    return range(1, 30).map((item) => ({ id: item, name: item }));
  }
};

//转换时间戳为字符串
export const format = (time, formatStr = "YYYY-MM-DD HH:mm:ss") => {
  return time ? moment(time).format(formatStr) : "-";
};

export function getYMDHMS(time) {
  let year = time.getFullYear(),
    month = time.getMonth() + 1,
    date = time.getDate(),
    hours = time.getHours(),
    minute = time.getMinutes(),
    second = time.getSeconds();

  if (month < 10) {
    month = "0" + month;
  }
  if (date < 10) {
    date = "0" + date;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minute < 10) {
    minute = "0" + minute;
  }
  if (second < 10) {
    second = "0" + second;
  }

  return {
    year: year,
    month: month,
    date: date,
    hours: hours,
    minute: minute,
    second: second,
  };
}
