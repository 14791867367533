<template>
  <TableWrap
    :formRef="formRef"
    :form="form"
    :search="search"
    :refresh="refresh"
    :pagination="pagination"
  >
    <template #queryform>
      <TableQueryForm ref="formRef" :model="form" :search="search">
        <a-form-item label="策略名称" name="strategyName">
          <a-input
            v-model:value="form.strategyName"
            placeholder="请输入策略名称"
          />
        </a-form-item>
      </TableQueryForm>
    </template>
    <template #toolbar>
      <a-button type="primary" @click="detailModalRef?.open()">
        <template #icon>
          <PlusOutlined />
        </template>
        新增
      </a-button>
    </template>
    <template #table>
      <a-table
        size="small"
        rowKey="servingId"
        bordered
        :columns="columns"
        v-bind="tableProps"
        :scroll="{ x: columnsWidth }"
        :pagination="false"
        :loading="tableProps.loading || delLoading"
      >
        <template #action="{ record }">
          <a-space :size="0" :key="record">
            <a-button
              v-if="!record.isDefault"
              size="small"
              type="link"
              @click="handleGoEdit(record)"
              >编辑</a-button
            >
            <a-popconfirm
              title="确定要删除吗？"
              v-if="!record.isDefault"
              :disabled="!!record.isDefault"
              @confirm="
                delRun({
                  strategyId: record.strategyId,
                });
                search.submit();
              "
            >
              <a-button :disabled="!!record.isDefault" type="link" size="small"
                >删除</a-button
              >
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </template>
  </TableWrap>
  <DetailModal ref="detailModalRef" @reload="search.submit" />
</template>
<script lang="ts">
import { ComputedRef, defineComponent, ref, provide, inject } from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import DetailModal from "./components/DetailModal.vue";
import { useForm, useTable, useDel, useUpdate } from "./use";
import { useSelectd } from "@/use/useSelectd";
import { PaginationConfig } from "ant-design-vue/lib/pagination";
import { XzcloudMessageStrategyPageListRes } from "./services";
import { useRoute, useRouter, Router } from "vue-router";

export default defineComponent({
  components: { DetailModal, PlusOutlined },
  setup() {
    const { form, formRef } = useForm();
    const activeKey = inject("activeKey");
    const router = useRouter();
    const { rowSelection } = useSelectd();
    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = useTable({
      form,
      formRef,
      router,
      activeKey,
    });

    const detailModalRef = ref<any>(null);
    // 编辑
    const { handleGoEdit } = useUpdate({ detailModalRef });
    // 删除
    const { run: delRun, loading: delLoading } = useDel({ search });
    provide("search", search as any);
    return {
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      pagination,
      detailModalRef,
      delRun,
      delLoading,
      handleGoEdit,
      rowSelection,
    };
  },
});
</script>
<style lang="less" scoped>
.servingIcon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  border: 1px solid #e8e8e8;
}
</style>
