<template>
  <a-drawer
    title="店铺服务"
    placement="right"
    width="320"
    :confirmLoading="detailLoading || addOrUpdateLoading"
    :visible="visible"
    :maskClosable="false"
    @close="handleClose"
  >
    <a-form ref="formRef" :model="form" v-bind="formLayout">
      <a-form-item
        label="服务图标"
        name="servingIcon"
        :rules="[{ type: 'array', required: true, message: '请上传服务图标' }]"
      >
        <UploadImg v-model="form.servingIcon" :maxLength="1" />
      </a-form-item>
      <a-form-item
        label="服务名称"
        name="servingName"
        :rules="[{ required: true, message: '请输入服务名称' }]"
      >
        <a-input v-model:value="form.servingName" :maxlength="64" />
      </a-form-item>
      <a-form-item label="服务说明" name="desc">
        <a-textarea v-model:value="form.desc" :maxlength="64" />
      </a-form-item>
      <a-form-item label="是否默认" name="isDefault">
        <a-switch v-model:checked="form.isDefault" />
      </a-form-item>
    </a-form>

    <div class="footer">
      <a-button style="margin-right: 8px" @click="handleClose">取消</a-button>
      <a-button type="primary" @click="handleOk">确定</a-button>
    </div>
  </a-drawer>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useModalState } from "xz-use";
import { message } from "ant-design-vue";
import UploadImg from "@/components/UploadImg/index.vue";
import { useDetail, useAddOrUpdate } from "./use";
import {
  SaveOrUpdateStoreServingParams,
  MessagePageListItem,
} from "../services";
import { key } from "@/store";

const getDefaultForm = () => ({});

type FormValues = {
  [key: string]: any;
};

export default defineComponent({
  components: { UploadImg },
  setup(props, { expose, emit }) {
    const form = ref<FormValues>(getDefaultForm());
    const formRef = ref<any>(null);

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      beforeOpen: async (record: MessagePageListItem) => {
        if (record) {
          const data = await detailRun({} as any);
          form.value = {};
        } else {
          form.value = getDefaultForm();
        }
      },
      afterClose: () => {
        formRef.value.clearValidate();
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    // 详情
    const { run: detailRun, loading: detailLoading } = useDetail();
    // 新增 || 修改
    const { run: addOrUpdateRun, loading: addOrUpdateLoading } =
      useAddOrUpdate();

    const handleOk = async () => {
      try {
        await formRef.value?.validateFields();
        await addOrUpdateRun({});
        message.success("保存成功");
        emit("reload");
        handleClose();
      } catch (e) {
        console.error(e);
      }
    };

    return {
      form,
      formRef,
      visible,
      handleClose,
      handleOk,
      detailRun,
      detailLoading,
      addOrUpdateRun,
      addOrUpdateLoading,
      formLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
      },
    };
  },
});
</script>
<style lang="less" scoped>
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
</style>
