<template>
  <TableWrap
    :formRef="formRef"
    :form="form"
    :search="search"
    :refresh="refresh"
    :pagination="pagination"
  >
    <template #queryform>
      <TableQueryForm ref="formRef" :model="form" :search="search">
        <a-form-item name="param" style="width: 300px">
          <a-input
            style="width: 300px"
            v-model:value="form.param"
            placeholder="请输入三方系统名称/识别码"
          />
        </a-form-item>
      </TableQueryForm>
    </template>
    <template #toolbar>
      <a-button type="primary" @click="detailModalRef?.open()">
        <template #icon>
          <PlusOutlined />
        </template>
        新增系统
      </a-button>
    </template>
    <template #table>
      <a-table
        size="small"
        rowKey="servingId"
        bordered
        :columns="columns"
        v-bind="tableProps"
        :scroll="{ x: columnsWidth }"
        :pagination="false"
        :loading="tableProps.loading || delLoading"
      >
        <template #action="{ record }">
          <a-space :size="0">
            <a-button type="link" size="small" @click="handleGoEdit(record)"
              >编辑</a-button
            >
            <a-popconfirm
              title="确定要删除吗？"
              @confirm="
                delRun({
                  sysId: record.sysId,
                });
                search.submit();
              "
            >
              <a-button type="link" size="small">删除</a-button>
            </a-popconfirm>
          </a-space>
        </template>
        <template #switch="{ record }">
          <a-switch
            size="small"
            :checked="record.isEnable"
            @change="
              (val) => {
                updateStatusRun({
                  isEnable: val,
                  sysId: record.sysId,
                });
              }
            "
          ></a-switch>
        </template>
      </a-table>
    </template>
  </TableWrap>
  <DetailModal ref="detailModalRef" @reload="search.submit" />
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import DetailModal from "./components/DetailModal.vue";
import { useForm, useTable, useDel, useUpdate, useStatus } from "./use";

export default defineComponent({
  components: { DetailModal, PlusOutlined },
  setup() {
    const { form, formRef } = useForm();
    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = useTable({
      form,
      formRef,
    });

    const detailModalRef = ref<any>(null);
    // 编辑
    const { handleGoEdit } = useUpdate({ detailModalRef });
    // 删除
    const { run: delRun, loading: delLoading } = useDel({ search });
    const { updateStatusRun } = useStatus({ search });

    return {
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      pagination,
      detailModalRef,
      delRun,
      delLoading,
      handleGoEdit,
      updateStatusRun,
    };
  },
});
</script>
<style lang="less" scoped>
.servingIcon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  border: 1px solid #e8e8e8;
}
</style>
