import { message } from "ant-design-vue";
import { computed, unref, reactive, provide, inject } from "vue";

const context = Symbol("selectedRes");

export const ProvideSelected = (props) => {
  provide(context, props);
};

export interface SelectedType<T> {
  rowSelection: any;
  isSelectd: () => boolean;
  selected: {
    selectedRowKeys: Array<string>;
    selectedRows: Array<T>;
  };
  disabled: boolean;
  clearSelectd: () => void;
}

//获取勾选上下文
export const InjectSelected = <T,>() => {
  return inject(context) as SelectedType<T>;
};

export const useSelectd = () => {
  const selected = reactive({
    selectedRowKeys: [],
    selectedRows: [],
  });

  //清空所有勾选
  const clearSelectd = () => {
    selected.selectedRowKeys = [];
    selected.selectedRows = [];
  };

  //是否禁用
  const disabled = computed(() => {
    return selected.selectedRowKeys.length == 0;
  });

  //判断是否勾选
  const isSelectd = () => {
    if (selected.selectedRowKeys.length == 0) {
      message.info("请至少选择一项");
      return false;
    }
    return true;
  };

  //更改选择项
  const onSelectChange = (changableRowKeys, changeableRows) => {
    selected.selectedRowKeys = changableRowKeys;
    selected.selectedRows = changeableRows;
  };
  const rowSelection = computed(() => {
    return {
      selectedRowKeys: unref(selected.selectedRowKeys),
      onChange: onSelectChange,
    };
  });

  ProvideSelected({
    rowSelection,
    selected,
    clearSelectd,
    isSelectd,
    disabled,
  });

  return {
    rowSelection,
    selected,
    clearSelectd,
    isSelectd,
    disabled,
  };
};
