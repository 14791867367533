
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import { useModalState, useRequest } from "xz-use";
import { setAuthCallBackUrl } from "../services";

export default defineComponent({
  setup(props, { expose, emit }) {
    const form = ref({ authCallbackUrl: "" });
    const formRef = ref<any>(null);
    const applicationId = ref<any>();

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      afterOpen: ({ authCallbackUrl, applicationId: id }) => {
        form.value.authCallbackUrl = authCallbackUrl;
        applicationId.value = id;
      },
      afterClose: () => {
        form.value = { authCallbackUrl: "" };
        formRef.value.clearValidate();
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    const { run: setAuthCallBackUrlRun, loading } = useRequest(
      setAuthCallBackUrl,
      {
        manual: true,
        onSuccess: () => {
          message.success("保存成功");
          emit("reload");
          handleClose();
        },
      }
    );

    const handleOk = async () => {
      try {
        await formRef.value?.validateFields();
        await setAuthCallBackUrlRun({
          applicationId: applicationId.value,
          authCallbackUrl: form.value.authCallbackUrl,
        });
      } catch (e) {
        console.error(e);
      }
    };

    return {
      loading,
      form,
      formRef,
      visible,
      handleClose,
      handleOk,
    };
  },
});
