import { httpGet, httpPost } from "@/config/axios-config";
import { PageParams, PageRes, PaginatedParams } from "@/services/public";

// 分页列表
export type MessageSubPageListForm = {
  servingName?: string;
};
export type MessageSubPageListParams = MessageSubPageListForm & PageParams;
export interface MessageSubPageListItem {
  createDate: string;
  desc: string;
  isDefault: boolean;
  servingIcon: string;
  servingId: string;
  servingName: string;
  openMessageSubscribeId: string;
  messageTemplateName: string;
  messageTemplateType: number;
  docUrl: string;
  strategyId: string;
  strategyName: string;
  subStatus: string;
  applicableSysList: any[];
}
export const messageSubPageList = (params: MessageSubPageListParams) =>
  httpPost<PageRes<MessageSubPageListItem>>(
    "/xzcloud/messageSub/pageList",
    params
  );
export const messageSubPageListForTable = (
  pageParams: PaginatedParams[0],
  formData: MessageSubPageListForm
) => {
  return messageSubPageList({
    ...pageParams,
    ...formData,
  }).then((res) => {
    return {
      total: res.total,
      list: res.records,
    };
  });
};

// 订阅
export interface messageSubBatchSubParams {
  subStatus: number;
  openMessageSubIds: number[];
}
export const messageSubBatchSub = (params: messageSubBatchSubParams) =>
  httpPost("/xzcloud/messageSub/batchSub", params);

export interface messageSubUpdateStrategiesParams {
  strategyId: number;
  openMessageSubIds: number[];
}

export const messageSubUpdateStrategies = (
  params: messageSubUpdateStrategiesParams
) => {
  return httpPost("/xzcloud/messageSub/updateStrategies", params);
};

export const messageStrategylist = () => {
  return httpGet("/xzcloud/messageStrategy/list");
};

//是否启用
export const updateEnableSubscribe = (params) => {
  return httpPost("/xzcloud/application/updateEnableSubscribe", params);
};

export const applicationInfo = () => {
  return httpGet("/xzcloud/application/selectEnableSubscribe");
};

// 当前应用详情
export interface XzcloudApplicationCurrentInfoParam {
  appId?: number;
}
export interface XzcloudApplicationCurrentInfoRes {
  /* appkey */
  appKey: string;

  /* appSecret */
  appSecret: string;

  /* 应用说明 */
  applicationContent: string;

  /* 应用id */
  applicationId: number;

  /* 授权系统数 */
  authorizeSysNum: number;

  /* 回调url */
  callBackUrl: string;

  /* 授权业务回调URL */
  authCallbackUrl: string;

  /* 创建事件 */
  createDate: string;

  /* 是否启用订阅 */
  enableSubscribe: boolean;

  /* 数据加密key */
  encodingAESKey: string;

  /* 白名单列表 */
  whiteIpList: string[];
}
export const xzcloudApplicationCurrentInfo = (
  params?: XzcloudApplicationCurrentInfoParam
) =>
  httpGet<XzcloudApplicationCurrentInfoRes>(
    "/xzcloud/application/currentInfo",
    params
  );

// 设置回调地址
export interface XzcloudApplicationSetCallbackUrlParams {
  /* 应用id */
  applicationId: number;

  /* 回调地址 */
  callbackUrl: string;
}
export const xzcloudApplicationSetCallbackUrl = (
  params: XzcloudApplicationSetCallbackUrlParams
) => httpPost("/xzcloud/application/setCallbackUrl", params);
