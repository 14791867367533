<template>
  <a-modal
    title="设置EncodingAESKey"
    :width="600"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @cancel="handleClose"
    @ok="handleOk"
  >
    <a-form ref="formRef" :model="form">
      <a-form-item
        label="EncodingAESKey"
        extra="消息加密密钥由43位字符串组成，可随机修改，字符范围为A-Z，a-z，0-9。"
        name="encodingKey"
        :rules="[{ required: true, message: '请生成EncodingAESKey' }]"
      >
        <a-input
          v-model:value="form.encodingKey"
          :maxlength="43"
          style="width: 360px"
        />
        <a-button type="link" @click="handleRandom">随机生成</a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script lang="ts">
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import { useModalState, useRequest } from "xz-use";
import { randomStr } from "./use";
import { xzcloudApplicationSetEncodingKey } from "../services";

export default defineComponent({
  setup(props, { expose, emit }) {
    const form = ref({ encodingKey: "" });
    const formRef = ref<any>(null);
    const applicationId = ref();

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      afterOpen: ({ encodingAESKey, applicationId: id }) => {
        form.value.encodingKey = encodingAESKey;
        applicationId.value = id;
      },
      afterClose: () => {
        form.value = { encodingKey: "" };
        formRef.value.clearValidate();
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    const { run, loading } = useRequest(
      () =>
        xzcloudApplicationSetEncodingKey({
          applicationId: applicationId.value,
          ...form.value,
        }),
      {
        manual: true,
        onSuccess: () => {
          message.success("保存成功");
          emit("reload");
          handleClose();
        },
      }
    );

    const handleOk = async () => {
      try {
        await formRef.value?.validateFields();
        await run();
      } catch (e) {
        console.error(e);
      }
    };

    const handleRandom = () => {
      form.value.encodingKey = randomStr(43);
    };

    return {
      loading,
      form,
      formRef,
      visible,
      handleClose,
      handleOk,
      handleRandom,
    };
  },
});
</script>
