// 接口文档
import { httpGet, httpPost } from "@/config/axios-config";
import { PageParams, PageRes, PaginatedParams } from "@/services/public";

// 推送策略分页列表
export interface XzcloudMessageStrategyPageListForm {
  /* 策略名称 */
  strategyName: string;
}
export type XzcloudMessageStrategyPageListParams = PageParams &
  XzcloudMessageStrategyPageListForm;
export interface XzcloudMessageStrategyPageListRes {
  /* 策略ID */
  strategyId: string;

  /* 策略名称 */
  strategyName: string;

  /* 策略描述 */
  content: string;

  /* 策略类型 */
  strategyType: number;

  /* 消息总数 */
  msgCount: number;

  /*  是否系统默认 0-否 1-是 */
  isDefault: number;

  /* 创建人 */
  userName: string;

  /* 创建日期 */
  createDate: string;
}
export const xzcloudMessageStrategyPageList = (
  params: XzcloudMessageStrategyPageListParams
) =>
  httpGet<PageRes<XzcloudMessageStrategyPageListRes>>(
    "/xzcloud/messageStrategy/pageList",
    params
  );

export const xzcloudMessageStrategyPageListForTable = (
  pageParams: PaginatedParams[0],
  formData: XzcloudMessageStrategyPageListForm
) => {
  return xzcloudMessageStrategyPageList({ ...pageParams, ...formData }).then(
    (res) => {
      return {
        total: res.total,
        list: res.records,
      };
    }
  );
};

// 推送策略所有系统类型
export interface XzcloudMessageStrategyAllSysRes {
  /* 发起方集合 */
  organizer: Organizer[];

  /* 接收方集合 */
  receiver: Organizer[];
}
interface Organizer {
  /* 系统ID */

  /* 系统ID */
  sysId: string;

  /* 系统名称 */

  /* 系统名称 */
  sysName: string;

  /* 系统类型 0-三方系统 1-智慧零售 2-GMC */

  /* 系统类型 0-三方系统 1-智慧零售 2-GMC */
  sysType: number;
}
export const xzcloudMessageStrategyAllSys = () =>
  httpGet<XzcloudMessageStrategyAllSysRes>("/xzcloud/messageStrategy/allSys");

// 推送策略列表（不分页）
export interface XzcloudMessageStrategyListRes {
  /* 策略ID */
  strategyId: string;

  /* 策略名称 */
  strategyName: string;

  /* 是否系统默认 0-否 1-是 */
  isDefault: number;
}
export const xzcloudMessageStrategyList = () =>
  httpGet<XzcloudMessageStrategyListRes[]>("/xzcloud/messageStrategy/list");

// 推送策略详情
export interface XzcloudMessageStrategyDetailParams {
  /* 推送策略ID */
  strategyId: string;
}
export interface XzcloudMessageStrategyDetailRes {
  /* 策略ID */
  strategyId: string;

  /* 推送策略名称 */
  strategyName: string;

  /* 推送策略描述 */
  content: string;

  /*  策略类型 2-指定排除 3-全部包含 */
  strategyType: number;

  /* 策略信息详情 */
  strategyInfo: StrategyInfo[];
}
interface StrategyInfo {
  /* 发起方ID */
  organizer: string;

  /* 发起方类型  0-三方系统 1-智慧零售 2-GMC */
  organizerType: string;

  /* 接收方集合 */
  receivers: Receiver[];
}
interface Receiver {
  /* 接收方ID */
  receiver: string;

  /* 接收方类型  0-三方系统 1-智慧零售 2-GMC */
  receiverType: string;
}
export const xzcloudMessageStrategyDetail = (
  params: XzcloudMessageStrategyDetailParams
) =>
  httpGet<XzcloudMessageStrategyDetailRes>(
    "/xzcloud/messageStrategy/detail",
    params
  );

// 推送策略编辑
export interface XzcloudMessageStrategyUpdateParams {
  strategyId: string;
  strategyName: string;
  remark: string;
  excludeSource: number;
  strategyInfo: StrategyInfo[];
}
interface StrategyInfo {
  organizer: string;
  organizerType: string;
  receivers: Receiver[];
}
interface Receiver {
  receiver: string;
  receiverType: string;
}
export const xzcloudMessageStrategyUpdate = (
  params: XzcloudMessageStrategyUpdateParams
) => httpPost("/xzcloud/messageStrategy/modify", params);

// 推送策略删除
export interface XzcloudMessageStrategyDelParams {
  /* 策略ID */
  strategyId: number;
}
export const xzcloudMessageStrategyDel = (
  params: XzcloudMessageStrategyDelParams
) => httpPost("/xzcloud/messageStrategy/del", params);
