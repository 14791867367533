import { computed, reactive, ref } from "vue";
import { useAntdTable, useRequest } from "xz-use";
import {
  xzcloudExtPointPageListForTable,
  XzcloudExtPointPageListForm,
  updateSubscribeUrl,
} from "@/services/extPointList";
import { message } from "ant-design-vue";

export const statusList = [
  { label: "已订阅", value: 2 },
  { label: "未订阅", value: 1 },
  { label: "无权限", value: 0 },
];

const getDefaultForm = () => ({
  status: null,
  extPointName: "",
  productAppType: null,
  productAppId: null,
  apiType: [],
});

export const useForm = () => {
  const form = ref(getDefaultForm());
  const formRef = ref<any>(null);
  const formReset = () => {
    form.value = getDefaultForm();
  };
  return {
    form,
    formRef,
    formReset,
  };
};

export const useTable = ({ form, formRef }) => {
  const tableRes = useAntdTable(xzcloudExtPointPageListForTable, {
    form,
    formRef,
    defaultParams: [{ pageSize: 20, current: 1 }],
  });

  const columns = [
    {
      title: "扩展点名称",
      width: 120,
      dataIndex: "extPointName",
      ellipsis: true,
    },
    { title: "分类", width: 90, dataIndex: "typeName", ellipsis: true },
    {
      title: "Event事件",
      width: 150,
      dataIndex: "extPointMark",
      ellipsis: true,
    },
    {
      title: "适用产品",
      width: 80,
      dataIndex: "productName",
      ellipsis: true,
    },
    {
      title: "是否支持多应用开启",
      width: 100,
      dataIndex: "more",
      customRender: () => {
        return "不支持";
      },
    },
    {
      title: "状态",
      width: 60,
      dataIndex: "status",
      customRender: ({ text }) => {
        return statusList.find((it) => it.value === text)?.label || "";
      },
    },
    {
      title: "操作",
      width: 90,
      fixed: "right",
      align: "center",
      slots: { customRender: "action" },
    },
  ];

  const columnsWidth = computed(() => {
    return columns.reduce((res, item) => {
      return res + item.width;
    }, 0);
  });

  return {
    tableRes,
    columns,
    columnsWidth,
  };
};

export const useUpdateurl = (load) => {
  return useRequest(updateSubscribeUrl, {
    manual: true,
    onSuccess: () => {
      message.success("地址设置成功");
      load();
    },
  });
};
