<template>
  <a-modal
    :visible="visible"
    :width="1000"
    :title="'推送策略'"
    :maskClosable="false"
    :destroyOnClose="true"
    @cancel="close"
    @ok="ok"
  >
    <!-- {{ form }} -->
    <!-- <br /> -->
    <!-- {{ dataMap }} -->
    <a-form
      v-bind="{
        labelCol: { md: { span: 4 }, sm: { span: 24 } },
        wrapperCol: { md: { span: 19 }, sm: { span: 24 } },
      }"
      ref="formRef"
      :model="form"
    >
      <a-form-item
        label="策略名称"
        name="strategyName"
        :rules="[
          { required: true, message: '策略名称不能为空' },
          { min: 0, max: 150, message: '策略名称不能超过50个字' },
        ]"
      >
        <a-input
          v-model:value="form.strategyName"
          placeholder="请输入策略名称"
        />
      </a-form-item>
      <a-form-item label="策略配置">
        <div class="ele-body">
          <a-table
            :columns="columns"
            :dataSource="dataSource"
            :pagination="false"
            :scroll="{ y: 400 }"
          >
          </a-table>
        </div>
      </a-form-item>
      <a-form-item
        label="排除来源"
        name="excludeSource"
        :extra="`
          当消息的来源方，调用接口触发了发起方事件时，设置排除来源，可以在推送方队列中排除掉来源方。
          此功能默认为关闭，不设置排除来源方，可以避免因上下行延迟造成的最终结果不一致问题。
      `"
      >
        <a-switch v-model:checked="form.excludeSource"> </a-switch>
      </a-form-item>
      <a-form-item
        label="备注"
        name="remark"
        :rules="[{ min: 0, max: 50, message: '备注不能超过50个字' }]"
      >
        <a-textarea
          required
          placeholder="请输入备注"
          style="height: 150px; width: 330px"
          v-model:value="form.remark"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="tsx">
import { computed, inject, reactive, ref } from "vue";
import { useRequest, useModalState } from "xz-use";
import { message } from "ant-design-vue";
import {} from "@ant-design/icons-vue";
import UploadImg from "@/components/UploadImg/index.vue";
import isEmpty from "lodash/isEmpty";
import { useForm } from "@/use/useForm";
import {
  xzcloudMessageStrategyAllSys,
  xzcloudMessageStrategyDetail,
  xzcloudMessageStrategyUpdate,
} from "../services";
import map from "lodash/map";

export default {
  components: {
    // UploadImg,
  },
  setup(props, { expose }) {
    const { formRef, form, note, reset } = useForm({
      excludeSource: false,
    });
    const search = inject("search") as any;
    const detailRef = ref();
    const { visible, handleOpen, handleClose } = useModalState({
      beforeOpen(record) {
        detailRun();
        if (record) {
          messageStrategyRun({
            strategyId: record.strategyId,
          });
        }
      },
      afterClose() {
        reset();
      },
    });
    const { run: detailRun, data } = useRequest(
      xzcloudMessageStrategyAllSys as any,
      {
        manual: true,
        throwOnError: true,
        initialData: {
          organizer: [],
          receiver: [],
        },
        formatResult: (data) => data,
      }
    );

    const { run: messageStrategyRun, data: messageStrategy } = useRequest(
      xzcloudMessageStrategyDetail as any,
      {
        manual: true,
        throwOnError: true,
        initialData: {},
        onSuccess(data: any) {
          note.value.strategyId = data?.strategyId;
          const resultMap = {
            2: true,
            3: false,
          };
          for (const item of data?.strategyInfo || []) {
            dataMap[item?.organizer] = map(
              item.receivers,
              ({ receiver, receiverType }) => {
                return {
                  sysType: receiverType,
                  sysId: receiver,
                };
              }
            );
          }
          form.value = {
            content: data.content,
            remark: data.content,
            strategyName: data.strategyName,
            excludeSource: resultMap[data.strategyType],
          };
        },
      }
    );

    const { run: addRun } = useRequest(
      (params) =>
        xzcloudMessageStrategyUpdate({
          ...params,
          strategyId: note.value.strategyId,
        }),
      {
        manual: true,
        onSuccess() {
          message.success("添加成功");
        },
      }
    );

    const isChecked = (organizer, receiver) => {
      if (!dataMap[organizer.sysId]) return false;
      return !!dataMap[organizer.sysId].find(
        (it) => it.sysId === receiver.sysId
      );
    };

    const dataSource = computed(() => {
      return (data.value as any).organizer;
    });

    let dataMap = reactive({});

    const handleChange = (e, organizer, receiver) => {
      const { checked } = e.target;
      if (!dataMap[organizer.sysId]) {
        dataMap[organizer.sysId] = [];
      }
      if (checked) {
        if (
          !dataMap[organizer.sysId].find((it) => it.sysId === receiver.sysId)
        ) {
          // 不存在push
          dataMap[organizer.sysId].push(receiver);
        }
      } else {
        dataMap[organizer.sysId] = dataMap[organizer.sysId].filter(
          (it) => it.sysId !== receiver.sysId
        );
      }
    };

    const columns = computed(() => {
      return [
        {
          title: (
            <div class="fisrt-th">
              <div class="fisrt-th-x">接收方</div>
              <div class="fisrt-th-y">发起方</div>
              <a-divider type="vertical" />
            </div>
          ),
          width: 100,
          dataIndex: "sysName",
        },
      ].concat(
        //接收方
        (data.value as any).receiver.map((receiver) => {
          return {
            title: receiver.sysName,
            // 发起方
            customRender: ({ record: organizer }) => {
              if (receiver.sysId === organizer.sysId) {
                return "-";
              }
              return (
                <a-checkbox
                  checked={isChecked(organizer, receiver)}
                  onChange={(e) => handleChange(e, organizer, receiver)}
                />
              );
            },
          };
        })
      );
    });

    //提交
    const ok = async () => {
      try {
        await formRef.value.validate();
        const strategyInfo: any = [];
        for (const [key, value] of Object.entries(dataMap)) {
          // 找到接收方对应的options
          // debugger;
          const target = (data.value.organizer || []).find(
            (item) => item.sysId == key
          );
          strategyInfo.push({
            organizer: target.sysId,
            organizerType: target.sysType,
            receivers: map(value as any, (item) => {
              const { sysId, sysType } = item;
              return {
                receiver: sysId,
                receiverType: sysType,
              };
            }),
          } as never);
        }
        await addRun({
          ...form.value,
          excludeSource: +form.value.excludeSource,
          strategyInfo,
        });
        handleClose();
        search.submit();
      } catch (error) {
        console.log(error);
      }
    };
    expose({
      open: handleOpen,
      close: handleClose,
    });
    return {
      detailRef,
      dataSource,
      visible,
      formRef,
      form,
      ok,
      close: handleClose,
      note,
      isEmpty,
      columns,
      data,
      dataMap,
    };
  },
};
</script>

<style lang="less" scoped>
.ele-body {
  &::v-deep(.ant-table-thead > tr:first-child > th:first-child) {
    position: relative;
  }
}
.fisrt-th-x {
  position: absolute;
  top: 8px;
  right: 0px;
}
.fisrt-th-y {
  position: absolute;
  bottom: 0px;
  left: 8px;
}
.ant-divider-vertical {
  position: absolute;
  transform: rotate(-70deg); /* 需要手调 ，斜线的角度*/
  transform-origin: top;
  top: 1px;
  left: -8px;
  height: 8.3em;
  background: #fafafa;
}
</style>
