export const sysType = [
  { id: 0, name: "外部" },
  { id: 1, name: "零售" },
  { id: 2, name: "GMC会员" },
  { id: 3, name: "三方" },
  { id: 4, name: "微商城" },
];

export const sysStatus = [
  { id: 1, name: "使用中" },
  { id: 2, name: "已过期" },
  { id: 3, name: "待付款" },
  { id: 4, name: "待审核" },
  { id: 5, name: "审核失败" },
  { id: 6, name: "创建中" },
];

export const authorizeStatus = [
  { id: 1, name: "待审核" },
  { id: 2, name: "已审核" },
  { id: 3, name: "审核失败" },
  { id: 4, name: "待授权" },
  { id: 5, name: "拒绝授权" },
  { id: 6, name: "已授权" },
  { id: 7, name: "已解除" },
  { id: 8, name: "已过期" },
];
