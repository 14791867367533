
import { defineComponent, ref } from "vue";
import TypeSelect from "@/components/Select/TypeSelect.vue";
import ProductSelect from "@/components/Select/ProductSelect.vue";
import { useForm, useTable } from "./use";
import { OwnStatusOptions } from "@/services/apiList";
import { useRequest } from "xz-use";
import { message } from "ant-design-vue/lib/components";
import { getAllApi } from "../service";
import {
  xzcloudOperBackgroundTypeList,
  xzcloudOperBackgroundProductList,
} from "@/services/apiList";

export default defineComponent({
  components: {},
  setup() {
    const { form, formRef } = useForm();
    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = useTable({
      form,
      formRef,
    });

    const { data: typeList } = useRequest(xzcloudOperBackgroundTypeList, {
      throwOnError: true,
      initialData: [],
    });

    const { data: productList } = useRequest(xzcloudOperBackgroundProductList, {
      throwOnError: true,
      initialData: [],
    });

    const { run: addRun, loading: addLoding } = useRequest(
      () => {
        return getAllApi();
      },

      {
        manual: true,
        onSuccess() {
          message.success("操作成功");
          search.submit();
        },
      }
    );

    // 查看文档
    const handleShowDoc = (docUrl) => {
      window.open(docUrl, "_blank");
    };

    return {
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      pagination,
      handleShowDoc,
      OwnStatusOptions,
      addRun,
      addLoding,
      typeList,
      productList,
    };
  },
});
