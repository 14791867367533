// api接口
import { httpGet, httpPost } from "@/config/axios-config";
import { PageParams, PageRes, PaginatedParams } from "@/services/public";

// 查询
export const extPointDetail = () =>
  httpGet<any>("/xzcloud/application/currentInfo");

//是否启用
export const updateEnableSubscribe = (params) => {
  return httpPost("/xzcloud/application/updateEnableExtPoint", params);
};

//修改地址
export const updateSubscribeUrl = (params) => {
  return httpPost("/xzcloud/application/setExtPointUrl", params);
};

//订阅
export const subscribe = (params) => {
  return httpPost("/xzcloud/extPoint/updateExtPointStatus", params);
};

//查询权限
export const getAllExtPoint = () => {
  return httpPost("/xzcloud/extPoint/getAllExtPoint");
};

// 列表查询（分页）
export interface XzcloudExtPointPageListForm {
  /* 名称 */
  extPointName: string;

  /* 分类 */
  apiType: any;

  /* 适用产品ID */
  productAppId: number;

  /* 适用产品type */
  productAppType: number | null;

  /* 拥有状态 0-未拥有 1-已拥有 */
  ownStatus: number;
}
export type XzcloudExtPointPageListParams = PageParams &
  XzcloudExtPointPageListForm;
export interface XzcloudExtPointPageListRes {
  /* Api ID */
  apiId: string;

  /* Api 名称 */
  apiName: string;

  /* Api 描述 */
  content: string;

  /* 文档地址 */
  docUrl: string;

  /* 接口限制信息 */
  limitInfo: string;

  /* 分类ID */
  typeId: string;

  /* 分类名称 */
  typeName: string;

  /* 拥有状态 0-未拥有 1-已拥有 */
  ownStatus: number;

  /* 适用系统集合 */
  applicableSysList: ApplicableSysList[];
}
interface ApplicableSysList {
  /* 系统ID */
  sysId: number;

  /* 系统名称 */
  sysName: string;

  /* 适用系统类型 1-主系统 2-子系统 */
  sysType: number;
}
export const xzcloudExtPointPageList = (
  params: XzcloudExtPointPageListParams
) =>
  httpGet<PageRes<XzcloudExtPointPageListRes>>(
    "/xzcloud/extPoint/findExtPointPage",
    params
  );

export const xzcloudExtPointPageListForTable = (
  pageParams: PaginatedParams[0],
  formData: XzcloudExtPointPageListForm
) => {
  const pageParam: any = {
    currentPage: pageParams["current"],
    pageSize: pageParams["size"],
  };
  let form = {
    ...formData,
  };
  if (form["apiType"]) {
    form["apiType"] = form["apiType"] + "";
  }
  return xzcloudExtPointPageList({ ...pageParam, ...form }).then((res) => {
    return {
      total: res.total,
      list: res.records,
    };
  });
};
