<template>
  <a-modal
    title="查看IP白名单"
    :width="600"
    :visible="visible"
    :maskClosable="false"
    okText="修改"
    @cancel="handleClose"
    @ok="handleOk"
  >
    <a-descriptions :column="1">
      <a-descriptions-item label="IP白名单">
        <div>
          <div class="des">在IP白名单内的IP来源，接口才可调用成功。</div>
          <div
            v-for="(item, index) in applyDetialInfo?.whiteIpList"
            :key="index"
          >
            {{ item }}<br />
          </div>
          <div v-if="applyDetialInfo?.whiteIpList.length === 0">暂无白名单</div>
        </div>
      </a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useModalState } from "xz-use";

export default defineComponent({
  setup(props, { expose }) {
    const setModalRef = ref<any>(null);
    const whiteIpList = ref<string[]>([]);
    const applyDetialInfo = ref();

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      afterOpen: ({ setWhiteModalRef, ...info }) => {
        setModalRef.value = setWhiteModalRef;
        applyDetialInfo.value = info;
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    const handleOk = async () => {
      try {
        setModalRef.value.open(applyDetialInfo.value);
        handleClose();
      } catch (e) {
        console.error(e);
      }
    };

    return {
      applyDetialInfo,
      visible,
      whiteIpList,
      handleClose,
      handleOk,
    };
  },
});
</script>

<style scoped lang="less">
.des {
  color: #999;
}
</style>
