export const authorizeStatus = [
  { id: 1, name: "待授权" },
  { id: 2, name: "已授权" },
  { id: 3, name: "已过期" },
];

export const appTypes = [
  { id: 1, name: "自研" },
  { id: 2, name: "工具型" },
];
