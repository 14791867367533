<template>
  <a-modal
    title="IP白名单设置"
    :width="600"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @cancel="handleClose"
    @ok="handleOk"
  >
    <a-form ref="formRef" :model="form">
      <a-form-item
        label="IP白名单"
        extra="多个白名单IP请使用换行区分。"
        name="ip"
      >
        <a-textarea v-model:value="form.whiteIpList" :rows="6" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script lang="ts">
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import { useModalState, useRequest } from "xz-use";
import { xzcloudApplicationSetIpWhiteList } from "../services";

export default defineComponent({
  setup(props, { expose, emit }) {
    const form = ref({ whiteIpList: "" });
    const formRef = ref<any>(null);
    const applicationId = ref();

    // Modal状态管理
    const { visible, handleOpen, handleClose } = useModalState({
      afterOpen: ({ whiteIpList, applicationId: id }) => {
        form.value.whiteIpList = whiteIpList.join("\r\n");
        applicationId.value = id;
      },
      afterClose: () => {
        form.value = { whiteIpList: "" };
        formRef.value.clearValidate();
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    const { run, loading } = useRequest(xzcloudApplicationSetIpWhiteList, {
      manual: true,
      onSuccess: () => {
        message.success("保存成功");
        emit("reload");
        handleClose();
      },
    });

    const handleOk = async () => {
      try {
        await formRef.value?.validateFields();
        console.log(form.value.whiteIpList);
        const ipArr = form.value.whiteIpList.split(/[(\r\n)\r\n]+/); // 转成数组
        await run({
          applicationId: applicationId.value,
          ipWhiteList: ipArr,
        });
      } catch (e) {
        console.error(e);
      }
    };

    return {
      loading,
      form,
      formRef,
      visible,
      handleClose,
      handleOk,
    };
  },
});
</script>
