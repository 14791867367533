
import { defineComponent, ref } from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import DetailModal from "./components/DetailModal.vue";
import { useForm, useTable, useDel, useUpdate } from "./use";
import { status } from "../options";
import { getAllMessag, messageTypeList, productList } from "./services";
import { useRequest } from "xz-use";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    DetailModal,
    // ProductSelect,
  },

  setup() {
    const { form, formRef } = useForm() as any;
    const { data } = useRequest(() => messageTypeList(), {
      throwOnError: true,
      initialData: [],
    });
    const { data: product }: { data: any } = useRequest(() => productList(), {
      throwOnError: true,
      initialData: [],
    });

    const { run: addRun, loading: addLoding } = useRequest(
      () => {
        return getAllMessag();
      },
      {
        manual: true,
        onSuccess() {
          message.success("操作成功");
          search.submit();
        },
      }
    );

    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = useTable({
      form,
      formRef,
    });

    const detailModalRef = ref<any>(null);
    // 编辑
    const { handleGoEdit } = useUpdate({ detailModalRef });
    // 删除
    const { run: delRun, loading: delLoading } = useDel({ search });

    return {
      data,
      product,
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      pagination,
      detailModalRef,
      delRun,
      delLoading,
      handleGoEdit,
      status,
      window,
      addRun,
      addLoding,
    };
  },
});
