
import { defineComponent, ref, inject, computed } from "vue";
import {} from "@ant-design/icons-vue";
import { useForm, useTable } from "../../use";
import { useSelectd, InjectSelected } from "@/use/useSelectd";
import { sysType } from "@/views/cloud/systemIntegration/authManage/list/options";
import { message } from "ant-design-vue";
import { AddApplicationAuthorizeSys } from "./service";
import { useRequest } from "xz-use";

export default defineComponent({
  components: {},
  setup() {
    const { form, formRef } = inject("useForm") as any;
    const { rowSelection } = InjectSelected();
    const getCheckboxProps = (record) => {
      return {
        disabled: !(record.status == "可授权"),
      };
    };

    const addRun = inject("addRun");
    const {
      tableRes: { tableProps, search, pagination, refresh },
      columns,
      columnsWidth,
    } = inject("useTable") as any;

    return {
      form,
      formRef,
      columns,
      columnsWidth,
      tableProps,
      search,
      refresh,
      rowSelection,
      pagination,
      sysType,
      addRun,
      getCheckboxProps,
    };
  },
});
