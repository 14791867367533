import { computed, reactive, ref } from "vue";
import { useAntdTable, useRequest } from "xz-use";
import { message } from "ant-design-vue";
import type { Search } from "@/services/public";
import { status } from "../options";
import map from "lodash/map";
import {
  delStoreServing,
  MessagePageListForTable,
  MessagePageListItem,
} from "./services";

export const useForm = () => {
  const form = reactive({
    msgName: null,
    types: [],
    productId: null,
    ownStatus: null,
    productType: null,
  });
  const formRef = ref<any>(null);
  return {
    form,
    formRef,
  };
};

export const useTable = ({ form, formRef }) => {
  const tableRes = useAntdTable(MessagePageListForTable, {
    form,
    formRef,
  });

  const columns = [
    { title: "消息名称", dataIndex: "messageTemplateName", width: 80 },
    { title: "分类", dataIndex: "messageTemplateType", width: 80 },
    { title: "Event事件", dataIndex: "eventCode", width: 80 },
    {
      title: "适用产品",
      dataIndex: "applicableSysList",
      ellipsis: true,
      width: 80,
      customRender: ({ text }) => {
        return map(text, "sysName").join(",");
      },
    },
    {
      title: "状态",
      dataIndex: "ownStatus",
      width: 60,
      customRender: ({ text }) => {
        return status.find((item) => item.id == text)?.name;
      },
    },
    {
      title: "操作",
      width: 80,
      fixed: "right",
      align: "center",
      slots: { customRender: "action" },
    },
  ];

  const columnsWidth = computed(() => {
    return columns.reduce((res, item) => {
      return res + item.width;
    }, 0);
  });
  return {
    tableRes,
    columns,
    columnsWidth,
  };
};

// 编辑
export const useUpdate = ({ detailModalRef }) => {
  const handleGoEdit = (record: MessagePageListItem) => {
    detailModalRef.value?.open(record);
  };
  return {
    handleGoEdit,
  };
};

// 删除
export const useDel = ({ search }: { search: Search }) => {
  return useRequest(delStoreServing, {
    manual: true,
    onSuccess: () => {
      message.success("删除成功");
      search.submit();
    },
  });
};
