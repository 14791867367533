import { useRequest } from "xz-use";
import { outerSysAdd, storeServingDetail } from "../services";

// 详情
export const useDetail = () => {
  return useRequest(storeServingDetail, {
    manual: true,
  });
};

// 新增
export const useAddOrUpdate = () => {
  return useRequest(outerSysAdd, {
    manual: true,
    throwOnError: true,
  });
};
