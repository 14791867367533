import { computed, reactive, ref } from "vue";
import { useAntdTable, useRequest } from "xz-use";
import { message } from "ant-design-vue";
import type { Search } from "@/services/public";
import querystring from "querystring";
import {
  messageSubPageListForTable,
  MessageSubPageListItem,
  messageSubBatchSub,
  messageSubUpdateStrategies,
} from "./services";
import { status } from "./options";

export const useForm = () => {
  const form = reactive({
    msgName: null,
    types: [],
    productId: null,
    strategyId: null,
    subStatus: null,
  });
  const formRef = ref<any>(null);
  return {
    form,
    formRef,
  };
};

export const useBatchSub = ({ search, clearSelectd }) => {
  const { run: BatchSubRun } = useRequest(messageSubBatchSub, {
    manual: true,
    throwOnError: true,
    onSuccess: () => {
      message.success("操作成功");
      clearSelectd();
      search.submit();
    },
  });
  return {
    BatchSubRun,
  };
};

export const useMessageSubUpdateStrategies = ({ search, clearSelectd }) => {
  const { run: messageSubUpdateStrategiesRun } = useRequest(
    messageSubUpdateStrategies,
    {
      manual: true,
      throwOnError: true,
      onSuccess: () => {
        message.success("操作成功");
        clearSelectd();
        search.submit();
      },
    }
  );
  return {
    messageSubUpdateStrategiesRun,
  };
};
export const useTable = ({ form, formRef }) => {
  const data = querystring.parse(
    location.href.slice(location.href.indexOf("?") + 1)
  );
  const tableRes = useAntdTable(messageSubPageListForTable, {
    form,
    formRef,
    defaultParams: [{ current: 1, pageSize: 20 }, data],
  });
  const columns = [
    { title: "消息名称", dataIndex: "messageTemplateName", width: 80 },
    { title: "分类", dataIndex: "messageTemplateType", width: 80 },
    { title: "Event事件", dataIndex: "eventCode", width: 80 },
    {
      title: "适用产品",
      dataIndex: "applicableSysList",
      ellipsis: true,
      width: 80,
      customRender: ({ text }) => {
        return (text || []).map((item) => item.sysName);
      },
    },
    {
      title: "推送策略",
      dataIndex: "strategyName",
      width: 80,

      customRender: ({ text }) => {
        return text ? text : "-";
      },
    },
    {
      title: "状态",
      dataIndex: "subStatus",
      width: 60,
      customRender: ({ text }) => {
        return status.find((item) => item.id == text)?.name;
      },
    },
    {
      title: "操作",
      width: 150,
      fixed: "right",
      align: "center",
      slots: { customRender: "action" },
    },
  ];

  const columnsWidth = computed(() => {
    return columns.reduce((res, item) => {
      return res + item.width;
    }, 0);
  });
  return {
    tableRes,
    columns,
    columnsWidth,
  };
};

// 编辑
export const useUpdate = ({ detailModalRef }) => {
  const handleGoEdit = (record: MessageSubPageListItem) => {
    detailModalRef.value?.open(record);
  };
  return {
    handleGoEdit,
  };
};
